const items = [
	{ id: 'en', name: 'English' },
	{ id: 'es', name: 'Español' },
	{ id: 'zh-TW', name: '繁體中文' },
	{ id: 'zh_CN', name: '简体中文' },
	{ id: 'ja', name: '日本語' },
	{ id: 'vi', name: 'Tiếng Việt' },
	{ id: 'ar', name: 'Arabic' },
	{ id: 'bn', name: 'Bengali' },
	{ id: 'bg', name: 'Bulgarian' },
	{ id: 'cs', name: 'Czech' },
	{ id: 'da', name: 'Danish' },
	{ id: 'nl', name: 'Dutch' },
	{ id: 'fi', name: 'Finnish' },
	{ id: 'fr', name: 'French' },
	{ id: 'de', name: 'German' },
	{ id: 'el', name: 'Greek' },
	{ id: 'gu', name: 'Gujarati' },
	{ id: 'he', name: 'Hebrew' },
	{ id: 'hi', name: 'Hindi' },
	{ id: 'hu', name: 'Hungarian' },
	{ id: 'id', name: 'Indonesian' },
	{ id: 'it', name: 'Italian' },
	{ id: 'ko', name: 'Korean' },
	{ id: 'ms', name: 'Malay' },
	{ id: 'mr', name: 'Marathi' },
	{ id: 'np', name: 'Norwegian' },
	{ id: 'pl', name: 'Polish' },
	{ id: 'pt', name: 'Portuguese' },
	{ id: 'pa', name: 'Punjabi' },
	{ id: 'ro', name: 'Romanian' },
	{ id: 'ru', name: 'Russian' },
	{ id: 'sv', name: 'Swedish' },
	{ id: 'ta', name: 'Tamil' },
	{ id: 'te', name: 'Telugu' },
	{ id: 'th', name: 'Thai' },
	{ id: 'tr', name: 'Turkish' },
	{ id: 'ul', name: 'Ukrainian' },
	{ id: 'ur', name: 'Urdu' },
];

export default items;
