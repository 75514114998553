function filterNumber(q) {
	let text = '--';
	try {
		text = q.toLocaleString('zh-Hant-TW');
	} catch (e) {
		text = q.toString();
	}
	return text;
}

function filterAssetUrl(q) {
	return process.env.ASSET_URL + q;
}

function filterDollar(q) {
	if (q < 0) {
		q = Math.abs(q);
		return '-$' + filterNumber(q);
	} else {
		return '$' + filterNumber(q);
	}
}

export { filterNumber, filterAssetUrl, filterDollar };

export default { filterNumber, filterAssetUrl, filterDollar };
