import axios from 'axios';

function get(url) {
	const config = {};

	const token = localStorage.getItem('token');
	if (token) {
		config.headers = {
			authorization: `Bearer ${token}`,
		};
	}

	// return axios.get(process.env.API_URL + url, config);
	return axios.get(window.apiUrl + url, config);
}

async function getAsync(url) {
	const config = {};

	const token = localStorage.getItem('token');
	if (token) {
		config.headers = {
			authorization: `Bearer ${token}`,
		};
	}

	// const result = await axios.get(process.env.API_URL + url, config);
	const result = await axios.get(window.apiUrl + url, config);

	return result.data;
}

// async function postAsync(url, data, headers = {}, onError = null) {
async function postAsync(url, data, headers = null, onError = null) {
	// let config = {};
	let config = { headers: {} };

	const token = localStorage.getItem('token');
	if (token) {
		config.headers = {
			authorization: `Bearer ${token}`,
		};
	}

	if (headers === null) {
		// yy
	} else {
		for (const i in headers) {
			const x = headers[i];
			// config[i] = x;
			config.headers[i] = x;
		}
		// config.headers = headers;
	}

	// let result = null;

	const self = this;

	// const result = await axios.post(process.env.API_URL + url, data, config).catch((err) => {
	const result = await axios.post(window.apiUrl + url, data, config).catch((err) => {
		try {
			self.hideLoading = false;
		} catch (e) {
			// yada
		}

		if (onError == null) {
			try {
				if (err.response.data.error) {
					console.error(`postAsync ~ error: ${err.response.data.error}`)
					// window.alert(err.response.data.error);
				} else {
					// window.alert('System is busy, please try again later.');
					self.isAlert.processing = false;
					self.isAlert.actions.pop();
				}
			} catch (e) {
				// yada
			}
		} else {
			onError(err);
			try {
				self.isAlert.processing = false;
				self.isAlert.actions.pop();
			} catch (e) {
				// yada
			}
		}
		return Promise.reject(err);
	});

	return result.data;
}

function post(url, data) {
	const config = {};

	const token = localStorage.getItem('token');
	if (token) {
		config.headers = {
			authorization: `Bearer ${token}`,
		};
	}

	const self = this;

	// return axios.post(process.env.API_URL + url, data, config).catch(function (error) {
	return axios.post(window.apiUrl + url, data, config).catch(function (error) {
		try {
			self.hideLoading = false;
		} catch (e) {
			// yada
		}
	});
}

async function deleteAsync(url, body, headers = null, onError = null) {
	let config = { 
		headers: {},
		data: body
	};

	const token = localStorage.getItem('token');
	if (token) {
		config.headers = {
			authorization: `Bearer ${token}`,
		};
	}

	if (headers === null) {
		// yy
	} else {
		for (const i in headers) {
			const x = headers[i];
			config.headers[i] = x;
		}
	}

	const self = this;

	const result = await axios.delete(window.apiUrl + url, config).catch((err) => {
		try {
			self.hideLoading = false;
		} catch (e) {
			// yada
		}

		if (onError == null) {
			try {
				if (err.response.data.error) {
					// window.alert(err.response.data.error);
					console.error(`deleteAsync ~ error: ${err.response.data.error}`)
				} else {
					// window.alert('System is busy, please try again later.');
					self.isAlert.processing = false;
					self.isAlert.actions.pop();
				}
			} catch (e) {
				// yada
			}
		} else {
			onError(err);
			try {
				self.isAlert.processing = false;
				self.isAlert.actions.pop();
			} catch (e) {
				// yada
			}
		}
		return Promise.reject(err);
	});

	return result.data;
}

function isEmpty(q) {
	return q === '' || q === null;
}

function alert(q) {
	window.alert(q);
}

// function getProductUrl(q) {
// 	const temp = productRedirect.find((z) => z.id == q.id);
// 	if (temp) {
// 		return '/product/' + temp.name;
// 	} else {
// 		return '/product/item/' + q.id + '/' + encodeURIComponent(q.name.replace(/\//g, '-'));
// 	}
// }

async function getNoneLoginToken() {
	const result = await this.postAsync('/appUserLogin/login', {
		userName: process.env.API_USERNAME,
		password: process.env.API_PASSWORD,
	});

	if (result.result) {
		this.token = result.data.token;
		localStorage.setItem('token', this.token);
	} else {
		console.error(`getNoneLoginToken ~ error: get token failed`)
		// window.alert('get token failed');
	}
 

	return result;
}

// login 取得的token 期間限定 60 min, 前端控制若有需要,自動更新用
async function refreshToken() {
	const result = await postAsync('/appUserLogin/refresh-token', {});

	/* 
	{
		"result": true,
		"data": {
		  "uuid": "760D18FD-F983-4B25-9C82-558296EE0CCA",
		  "email": "testerno1.realsun@gmail.com",
		  "appUserId": "1",
		  "message": "Successfully refresh token!!",
		  "token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1dWlkIjoiNzYwRDE4RkQtRjk4My00QjI1LTlDODItNTU4Mjk2RUUwQ0NBIiwiZW1haWwiOiJ0ZXN0ZXJubzEucmVhbHN1bkBnbWFpbC5jb20iLCJleHBpcmVzSW4iOjM2MDAsImlhdCI6MTY5MzAwNjkwOSwiZXhwIjoxNjkzMDEwNTA5fQ.Q9IvAlhE94SyBey6I-_zxV8PwnKsSZO7BCQc9seY-fk0pcxZvR81qiaJLhRqeMagBE5m1X0t6h4ojeW8i7cVQUlaziM6emwLkSCz0E-s1e2ZuubpNLbszOUgIGivX7LCI7gMnZMy8p4rD7btd9-HWs0hzQKK_T9Ta-UL3Y_JzbxoBieSg0YQhGZbKX4Wl6G8v9Zyw7eTRzO9ohJy7eEptNOFuEF4NuPiFktjb6g2ZtjvcuQ4d6URvm4-FGiCpb8yE7FBMk0c8VsI5warncM7BG07I_c2JMXudcBmOUjxAsqVXLp4jmcZ7WpNN_iOdjuShXRfi4NPrcxeFNUtm_0CfQ"
		}
	  } */

	if (result.result) {
		this.token = result.data.token;
		localStorage.setItem('token', this.token);
	} else {
		console.error(`refreshToken ~ error: refresh token failed`)
		// window.alert('refresh token failed');
	}
}

function getToday(divide = '-') {
	// Get current date

	const today = new Date();

	// Extract year, month, and day
	const year = today.getFullYear();
	const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
	const day = today.getDate().toString().padStart(2, '0');

	// Format the date as 'YYYY/MM/DD'
	const formattedDate = `${year}${divide}${month}${divide}${day}`;

	return formattedDate;
}

function getDate(q = null) {
	try {
		let date = new Date(q) || new Date();

		const offsetMinutes = date.getTimezoneOffset();
		date.setMinutes(date.getMinutes() - offsetMinutes);

		// Get year, month, and date components
		const year = date.getFullYear();
		// Add 1 to month because getMonth() returns values from 0 to 11
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');

		// Return the formatted date
		return `${year}-${month}-${day}`;
	} catch (e) {
		return '--';
	}
}

function getHourMinute(q = null) {
	// Get current time
	try {
		let date = new Date();
		try {
			if (q != null) {
				date = new Date(q);
			}
		} catch (e) {
			// yada
			date = new Date();
		}

		const offsetMinutes = date.getTimezoneOffset();
		date.setMinutes(date.getMinutes() - offsetMinutes);

		// Extract hours and minutes
		let hours = date.getHours();
		const minutes = date.getMinutes();

		// Determine AM/PM
		const amOrPm = hours >= 12 ? 'PM' : 'AM';

		// Convert to 12-hour format
		hours = hours % 12 || 12;

		// Format the time as 'hh:mm AM/PM'
		const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

		return formattedTime;
	} catch (e) {
		return '--';
	}
}

function createCaptcha() {
	//clear the contents of captcha div first
	document.getElementById('captchaFrame').innerHTML = '';
	// let charsArray = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*';
	let charsArray = '0123456789';
	// let lengthOtp = 6;
	let lengthOtp = 4;
	let captcha = [];
	for (let i = 0; i < lengthOtp; i++) {
		//below code will not allow Repetition of Characters
		// let index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array

		let randomIndex = Math.floor(Math.random() * charsArray.length);

		captcha.push(charsArray[randomIndex]);
		// if (captcha.indexOf(charsArray[index]) == -1) captcha.push(charsArray[index]);
		// else i--;
	}

	let canv = document.createElement('canvas');
	canv.id = 'captcha';
	// canv.width = 100;
	canv.width = 80;
	// canv.height = 50;
	canv.height = 45;
	let ctx = canv.getContext('2d');
	ctx.font = '25px Georgia';
	ctx.strokeText(captcha.join(''), 0, 30);
	//storing captcha so that can validate you can save it somewhere else according to your specific requirements
	this.captchaAnswer = captcha.join('');

	if (this.isDev) {
		this.captcha = this.captchaAnswer;
	}

	document.getElementById('captchaFrame').appendChild(canv); // adds the canvas to the body element
}

async function getBlobData(blobUuid) {
	let data = '';
	const url = `/qa/getBlob/${blobUuid}`;
	const res = await getAsync(url);
	// console.log("🚀 ~ getBlobData ~ res:", res)
	if (res.result) {
		data = res?.data[0]?.data;
	}
	return data;
}

export { post, postAsync, get, isEmpty, alert, getNoneLoginToken, refreshToken, getAsync, getToday, getHourMinute, createCaptcha, getDate, deleteAsync, getBlobData };

export default { post, postAsync, get, isEmpty, alert, getNoneLoginToken, refreshToken, getAsync, getToday, getHourMinute, createCaptcha, getDate, deleteAsync, getBlobData };
