<template>
	<router-view />

	<!-- <router-view v-if="isDesktop" /> -->

	<!-- <div v-else class="h-screen w-screen bg-[#00467F] flex justify-between flex-col text-16"> -->
		<!-- <div>Redirecting...</div> -->
		<!-- <a href="https://www.youtube.com/watch?v=lvWBzVZ28Wo" target="_blank"> 我是youtube </a> -->
		<!--   
		<div></div>
		<div class="container">
			<div class="text-white">
				<div class="text-center text-24">Opps!</div>
				<div class="h-5"></div>
				<div class="text-center text-24">Not support small screen</div>
				<div class="h-5"></div>

				<img src="/img/icon-18.png" class="w-1/5 mx-auto" />
				<div class="h-5"></div>

				<div>Please use a desktop-sized screen for the best browsing experience.</div>
				<div class="h-10"></div>
				<div class="h-10"></div>
			</div>
		</div>

		<div class="container">
			<div class="flex items-center justify-center text-black">
				<img src="/img/icon-19.png" class="w-[20px] mr-2" />
				<div class="text-16 text-white">Dr.AI</div>
			</div>
			<div class="h-3"></div>

			<div class="text-center text-12">Copyright © 2023 INTOWELL Biomedical Technology, Inc. | Powered by eXpertMind</div>

			<div class="h-10"></div>
		</div> -->
	<!-- </div> -->
</template>

<script>
import { useI18n } from 'vue-i18n';

import { watch } from 'vue';
import router from './router';

export default {
	components: {},

	setup() {
		// const { t, locale } = useI18n();
		const { locale } = useI18n();

		watch(locale, (newlocale) => {
			localStorage.setItem('locale', newlocale);
			document.documentElement.setAttribute('lang', newlocale);
		});

		// const store = useStore();

		return {
			// t,
			locale,
			// store,
		};
	},

	data() {
		return {
			isDesktop: false,
			// isDesktop: true,
		};
	},

	mounted() {
		// check device width
		const width = window.innerWidth;
		// is mobile

		// alert(navigator.language); 

		// 2024-04-12 最後可以幫我把，當初有設定螢幕太小會直接導入到YouTube這個功能先關掉嗎
		if (width < 768) {

			this.isDesktop = false;

			// 目前手機版的暫時畫面
			// router.push('/welcome-jump-for-mobile')

			// // 取得使用者的語言
			// let language = navigator.language;

			// // 如果語言為「繁體中文」
			// if (language === 'zh-TW') {
			// 	// 轉址到繁體中文的版本的網站
			// 	document.location = 'https://www.youtube.com/embed/usN22FEJ6rA?autoplay=1';
			// } else {
			// 	// 轉址到英文的版本的網站
			// 	document.location = 'https://www.youtube.com/embed/MjSc91MzUdI?autoplay=1';
			// }
		} else {
			this.isDesktop = true;
		}

		// check router meta

		const self = this;
		setInterval(function () {
			const isLogin = self.$route.meta.isLogin;


			if (isLogin != undefined && isLogin === true) {

				try {
					const now = new Date().valueOf();

					const tokenExpiredAt = parseInt(localStorage.getItem('tokenExpiredAt'));

					// if (tokenExpiredAt - now <= 600000) {
					// if (tokenExpiredAt - now <= 7200000) {
					if (tokenExpiredAt - now <= 1800000) {
						// 30 minutes

						self.store.refreshToken();
					}
				} catch (e) {
					// yada
				}

				// this.store.getUserProfile();
			}
			// }, 1000);
		}, 60000);
	},

	methods: {},
};
</script>
