//#region import

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import pinia from './store/index';
import i18n from './i18n.js';

// helpers
import helperFilter from './helpers/filter.js';
import helperMain, { createCaptcha, getNoneLoginToken, refreshToken } from './helpers/main.js';
import constants from './constants/_index.js';

// store
import { useStore } from './store/main';

// main css
import './styles/_index.css';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons' // far
import { fas } from '@fortawesome/free-solid-svg-icons' // fas

import moment from 'moment';

/**primevue */
import 'primevue/resources/themes/aura-light-green/theme.css'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
// import Tooltip from 'primevue/tooltip';
// import Button from "primevue/button"
// import Menu from 'primevue/menu';

import { AxiosResponse } from 'axios';
import { LayoutLanguage } from './language/language.model.js';

/**sign in with apple */
import VueAppleLogin from 'vue-apple-login';

/**sign in with google */
import vue3GoogleLogin from 'vue3-google-login'

// import Antd from "ant-design-vue";

//#endregion



//#region declare global

declare global {
    interface Window {
        isLogin: boolean
        appleLogin: {
            clientId: string
            redirectURI: string
        }
        googleLogin: {
            clientId: string
        }
        apiUrl: string
        sttApiUrl: string
    }
}

//#endregion




//#region ComponentCustomProperties

export {}

declare module 'vue' {
    interface ComponentCustomProperties {
        // globalProperties
        isProduction: boolean
        isDev: boolean
        option: {
            gender: {
                id: number;
                name: string;
            }[];
            is: {
                id: boolean;
                name: string;
            }[];
            qaFrom: {
                id: number;
                name: string;
            }[];
            replyLanguage: {
                id: string;
                name: string;
            }[];
            tokenExipireInterval: number;
            layoutLanguage: LayoutLanguage[];
        }
        version: string
        isMobile: boolean
        store: {
            [key: string]: any
            user: {
                id: string;
                status: number;
                statusStr: string;
                uuid: string;
                email: string;
                idNo?: any;
                userName: string;
                nickName: string;
                gender?: any;
                genderStr: string;
                birthday?: any;
                mobile: string;
                updateTime: string;
                updateUserId: string;
                isFirstLogin: boolean;
                totalBalance: number;
                extData: {
                    id: number;
                    appUserId: number;
                    kind: number;
                    kindStr: string;
                    value1: string;
                    value2?: any;
                    timestamp: string;
                }[];
                doctor?: any;
                collection?: any;
                topic?: any;
                photo: {
                    id: number;
                    kind: number;
                    kindStr: string;
                }[];
                setting?: any;
                package: {
                    id: number;
                    appUserId: number;
                    coinPkgId: number;
                    status: number;
                    buyDateTime: string;
                    creditExpired: string;
                    readExpired: string;
                    updateUserId: number;
                    timestamp: string;
                    isLock?: any;
                    unLockDateTime?: any;
                    packageName: string;
                    packageDesc: string;
                    packageAmount: number;
                    packageValidDays: number;
                    packageQueryDays: number;
                    packageDollar: number;
                    packageCycle: number;
                    packageCycleStr: string;
                    packageKind: number;
                    packageKindStr: string;
                    packageVersion: number;
                    packageVersionStr: string;
                    creditExpiredDate: string;
                }[];
                coin: {
                    id: number;
                    appUserId: number;
                    version: string;
                    totalAmount: number;
                    updateTime: string;
                }[];
            } | any
        }

        // helperFilter
        filterNumber: (q: any) => string
        filterAssetUrl: (q: any) => any
        filterDollar: (q: any) => string

        // helperMain
        post: (url: any, data: any) => Promise<void | AxiosResponse<any, any>>
        postAsync: (url: any, data: any, headers?: null, onError?: null) => Promise<any>
        get: (url: any) => Promise<AxiosResponse<any, any>>
        isEmpty: (q: any) => boolean
        alert: (q: any) => void
        getNoneLoginToken: typeof getNoneLoginToken
        refreshToken: typeof refreshToken
        getAsync: (url: any) => Promise<any>
        getToday: (divide?: string) => string
        getHourMinute: (q?: null) => string
        createCaptcha: typeof createCaptcha
        getDate: (q?: null) => string
        deleteAsync: (url: any, body: any, headers?: null, onError?: null) => Promise<any>
        getBlobData: (blobUuid: any) => Promise<string>

        // refs
        $refs: {
            fileInput: HTMLInputElement
        }
    }
}

//#endregion




//#region const

const version = '20240819.01'

const isProduction = process.env.NODE_ENV === 'production';

const width = window.innerWidth;

const app = createApp(App);

//#endregion




//#region globalProperties

// global mixin
app.config.globalProperties.isProduction = isProduction;
app.config.globalProperties.isDev = !isProduction;

// merge helperFilter into app.config.globalProperties
app.config.globalProperties = { ...app.config.globalProperties, ...helperFilter };
app.config.globalProperties = { ...app.config.globalProperties, ...helperMain };

// constants into option
app.config.globalProperties.option = constants;

app.config.globalProperties.version = version;

app.config.globalProperties.isMobile = width < 768;

//#endregion

/* font awesome: add icons to the library */
library.add(faYoutube, far, fas)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('font-awesome-layers', FontAwesomeLayers)

// i18n
app.use(i18n);

// primevue
app.use(PrimeVue);
app.use(ToastService);
app.component('Toast', Toast);
// app.directive('tooltip', Tooltip);
// app.component('Button', Button);
// app.component('Menu', Menu);

// app.use(Antd);

// sign in with apple
app.use(VueAppleLogin, {
    clientId: window.appleLogin.clientId,
    scope: 'name email',
    redirectURI: window.appleLogin.redirectURI,
    state: moment(new Date()).format('YYYY-MM-DD'),
    usePopup: true,
})

// sign in with google
app.use(vue3GoogleLogin, {
    clientId: window.googleLogin.clientId
})

//#region pinia

app.use(pinia);

const store = useStore();

app.config.globalProperties.store = store;

//#endregion

app.use(router);

app.mount('#app');

// console 檢核版本用
console.log(`DATETIME: ${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}, VERSION: ${version}.001`);