import { I18nString, i18nString_en, i18nString_es, i18nString_jp, i18nString_kr, i18nString_sc, i18nString_tw, i18nString_vi } from "./i18nString.model"

/**i18多語 */
export enum I18nLang {
    English = 'en',
    Spanish = 'es',
    繁體中文 = 'tw',
    简体中文 = 'sc',
    日本語 = 'jp',
    한국어 = 'kr',
    Vietnamese = 'vi'
}

/**i18多語列表 */
export interface LayoutLanguage {
    id: I18nLang
    name: string
    i18nString: I18nString
}

/**i18多語列表 */
export const layoutLanguage: LayoutLanguage[] = [
    { id: I18nLang.English, name: Object.keys(I18nLang)[Object.values(I18nLang).indexOf(I18nLang.English)], i18nString: i18nString_en },
    { id: I18nLang.Spanish, name: Object.keys(I18nLang)[Object.values(I18nLang).indexOf(I18nLang.Spanish)], i18nString: i18nString_es },
    { id: I18nLang.繁體中文, name: Object.keys(I18nLang)[Object.values(I18nLang).indexOf(I18nLang.繁體中文)], i18nString: i18nString_tw },
    { id: I18nLang.简体中文, name: Object.keys(I18nLang)[Object.values(I18nLang).indexOf(I18nLang.简体中文)], i18nString: i18nString_sc },
    { id: I18nLang.日本語, name: Object.keys(I18nLang)[Object.values(I18nLang).indexOf(I18nLang.日本語)], i18nString: i18nString_jp },
    { id: I18nLang.한국어, name: Object.keys(I18nLang)[Object.values(I18nLang).indexOf(I18nLang.한국어)], i18nString: i18nString_kr },
    { id: I18nLang.Vietnamese, name: Object.keys(I18nLang)[Object.values(I18nLang).indexOf(I18nLang.Vietnamese)], i18nString: i18nString_vi },
]

/**
 * 取得多語messages
 * 
 * i18n resources: messages
 * @returns { [key: string]: I18nString }
 * 
 * ```
 * messages: {
        en: en,
        tw: tw,
        jp: jp,
        vi: vi,
        sc: sc,
        es: es,
    },
    ```
 */
export function getI18nMessages(): { [key: string]: I18nString } {
    const messages: { [key: string]: I18nString } = {}
    layoutLanguage.forEach(lang => messages[lang.id] = lang.i18nString)
    return messages
}