import gender from './gender';
import is from './is';
import qaFrom from './qaFrom';
import replyLanguage from './replyLanguage';
import { layoutLanguage } from '../language/language.model';

const tokenExipireInterval = 3600000; // 1 hour

const item = {
	gender,
	is,
	qaFrom,
	replyLanguage,
	tokenExipireInterval,
	layoutLanguage,
};

export default item;

export { gender, is, qaFrom, replyLanguage, tokenExipireInterval, layoutLanguage };
