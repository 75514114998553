import { defineStore } from 'pinia';

import { get, getAsync } from '../helpers/main';

import { tokenExipireInterval } from '../constants/_index';

export const useStore = defineStore('Main', {
	state: () => ({
		user: {
			id: null,
			status: null,
			statusStr: null,
			uuid: null,
			email: null,
			idNo: null,
			userName: null,
			nickName: null,
			gender: null,
			genderStr: null,
			birthday: null,
			mobile: null,
			updateTime: null,
			updateUserId: null,
			extData: null,
			doctor: null,
			collection: null,
			topic: null,
			photo: null,
			setting: null,
			package: [],
		},

		isStatusChecked: false,
		isLogin: false,

		isInvalidToken: false,
	}),

	getters: {},

	actions: {
		refreshToken() {
			get('/appUserLogin/refresh-token').then(function (response) {
				if (response.data.result === true) {
					const token = response.data.data.token;
					localStorage.setItem('token', token);
					const timestampNow = new Date().valueOf() + tokenExipireInterval;
					localStorage.setItem('tokenExpiredAt', timestampNow);
				}
			});
		},

		async getUserProfile() {
			const self = this;

			const res = await getAsync('/appUserLogin/getUserProfile');
			// console.log("🚀 ~ getUserProfile ~ res:", res)

			if (res.result) {
				window.isLogin = true;
				const appUserData = res.data.appUser
				if (!!appUserData.package && appUserData.package.length > 0) {
					appUserData.package[0].creditExpiredDate = res.data.appUser.package[0].creditExpired.substring(0, 10);
				}

				self.setUser(appUserData);
			}
			// 保留
			// get('/appUserLogin/getUserProfile').then(function (response) {
			// 	if (response.data.result === true) {
			// 		window.isLogin = true;

			// 		try {
			// 			response.data.data.appUser.package[0].creditExpiredDate = response.data.data.appUser.package[0].creditExpired.substring(0, 10);
			// 		} catch (e) {
			// 			// yada
			// 		}

			// 		self.setUser(response.data.data.appUser);
			// 	}
			// });
		},

		setStatus() {
			this.isStatusChecked = true;
		},

		setUser(q) {
			this.user = q;

			window.isLogin = true;
		},

		logout() {
			this.user = {
				id: null,
				status: null,
				statusStr: null,
				uuid: null,
				email: null,
				idNo: null,
				userName: null,
				nickName: null,
				gender: null,
				genderStr: null,
				birthday: null,
				mobile: null,
				updateTime: null,
				updateUserId: null,
				extData: null,
				doctor: null,
				collection: null,
				topic: null,
				photo: null,
				setting: null,
				package: [],
			};
			this.isLogin = false;

			window.isLogin = false;
			localStorage.removeItem('token');
		},
	},
});
