const items = [
	{
		id: 1,
		name: 'user',
	},
	{
		id: 2,
		name: 'ai',
	},
];

export default items;
