/**i18n多語字串 */
export interface I18nString {
	[key: string]: any
	Copyright_Desc?: string
	"Welcome to Dr.AI": string
	Pro_Home_Title2: string
	"Get Started": string
	"Dr.AI": string
	"Today": string
	"+ New Case": string
	"-- Case is empty --": string
	"credits": string
	"Valid until": string
	"Logout": string
	"Admission Note": string
	"Progress Note": string
	"Pharmacotherapy": string
	"Cancer Strategy": string
	"Any Questions": string
	"Excellent": string
	"Good": string
	"Fair": string
	"Poor": string
	"Generating. Results will be ready in 1-3 minutes.": string
	"Regenerate": string
	"Refine Admission Note & Generate Admission Orders": string
	"Edit": string
	"Save": string
	"Please enter a Note Title using a keyword or the patient's chart number for easy search.": string
	"Admission Date": string
	"Note Title": string
	"Your case note": string
	"Title": string
	"Copy": string
	"Download": string
	"Save As": string
	"Rename Title": string
	"Edit Message": string
	"Your account balance is now depleted.": string
	"My Package": string
	"Cancel": string
	"Please click on [Our plan] to access recharge functions.": string
	"Are you sure you want to delete this information": string
	"Yes": string
	"Disclaimer for Dr.AI": string
	"I agree": string
	"Next": string
	"Introducing Dr.AI": string
	"Embracing the Future of Healthcare, Combing the Best of AI Technologies": string
	"Empowering Healthcare Professionals:": string
	"Unparalleled Data Analysis": string
	"Assured Quality": string
	"Proactive Health Predictions": string
	"Personalized Treatments": string
	"Collaborative Healthcare": string
	"Evidence-Informed Recommendations": string
	"Go Back": string
	"Please enter your email and follow the provided instructions to complete your password change.": string
	"Forgot password": string
	"We have already sent a verification email to": string
	"Email": string
	"Submit": string
	Auth_Error_Msg1: string
	"Enter verification code": string
	"Resending…": string
	"Resend Code": string
	"Log In": string
	"Email Address": string
	"Sign up": string
	"Hello": string
	"Password": string
	"Forgot password?": string
	"Choose your plan": string
	"Select the best plan for your needs": string
	"FREE Trial": string
	"USD for 3 months": string
	"Sign up and instantly receive 300 credits!": string
	"300 credits for use": string
	"3-month trial period": string
	"6 months of data preservation": string
	"Apply for Free Trial": string
	"Basic": string
	"USD for 1 year": string
	"Begin experimenting with 300 credits over the course of a year.": string
	"1-year validity": string
	"18 months of data preservation": string
	"Includes data recovery (available upon credit refill)": string
	"Coming Soon": string
	"Plus": string
	"For more flexibility, start experimenting with 600 credits over a one-year span.": string
	"600 credits for use": string
	"Premium": string
	"USD per month": string
	"Unlimited access tailored for healthcare professionals.": string
	"Unlimited credits": string
	Pro_Pack_Item6: string
	"Biomedical Technology, Inc.": string
	"not found": string
	"Create a New Account": string
	"Male"?: string
	"Female"?: string
	"Set up a password": string
	"Confirm Password": string
	"Your Detail"?: string
	Pro_Home_Content1: string
	"To thoroughly evaluate the patient's clinical conditions, Dr. AI needs specific information. This includes the patient's chief complaint, details of the current illness, a review of systems, the patient's past medical history, findings from the physical examination, and laboratory results. It is important to exclude any personally identifiable information, such as the patient's name or ID, from this data.": string
	"Select the appropriate Admission Note for evaluation from the left column, and provide details regarding the Subjective (S) and Objective (O) aspects of the patient's condition. This should include the patient's symptoms, observable signs, and laboratory results. Please avoid including any personally identifiable information, such as the patient's name or ID. If the sidebar does not offer any selection options, it indicates that you need to first create the patient's Admission Note before moving on to the Progress Note analysis with Dr. AI.": string
	"On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.": string
	"Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.": string
	"Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.": string
	"Don't have an account?": string
	"Search...": string
	"Please check your email for the 6-digit verification code and enter it in the fields below": string
	"Chief complaint, Present illness, Review of systems, Past medical history, Physical exam, and Laboratory results": string
	"The Subjective and Objective elements include the patient's symptoms, clinical signs, and Laboratory data": string
	"Questions regarding the patient's current medication and pertinent pharmacotherapy regimen.": string
	"Details on cancer type and stage, medical history, pathology reports, genetic testing results, performance status, radiological findings, treatment history, and patient preferences": string
	"Raise any questions": string
	"Your Email": string
	"fill in Captcha": string
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, and numbers.": string
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.": string
	"Enter password": string
	"Inquiries concerning the patient's ongoing medication or relevant pharmacotherapy regimen.": string
	"Please enter your email and follow the instructions to finish changing your password.": string
	Pro_Home_Link1: string
	Pro_Jump_Title1: string
	Pro_Jump_Title2: string
	Pro_Jump_Content1: string
	Pro_Jump_Content2: string
	Pro_Main_Menu1: string
	Pro_Main_Menu2: string
	Pro_Main_Menu3: string
	Pro_SOAP_TTL: string
	Pro_SOAP_DSP: string
	Pro_SOAP_TIP: string
	Pro_NursingN_TTL: string
	Pro_NursingN_DSP: string
	Pro_NursingN_TIP: string
	Pro_ConsultD_TTL: string
	Pro_ConsultD_DSP: string
	Pro_ConsultD_TIP: string
	Pro_MeetingR_TTL: string
	Pro_MeetingR_DSP: string
	Pro_MeetingR_TIP: string
	Pro_MS_List_TTL: string
	Pro_MS_List_DSP: string
	Pro_MS_List_Msg1: string
	Pro_MS_Recording_TTL: string
	Pro_MS_Recording_Msg1: string
	Pro_MS_Upload_TTL: string
	Pro_MS_Upload_DSP: string
	Pro_MS_Upload_Msg1: string
	Pro_MS_Upload_Msg2: string
	Pro_MS_Upload_Msg2_Size: string
	Pro_MS_Upload_Msg3: string
	Pro_MS_Upload_Msg4: string
	Pro_MS_Upload_Msg5: string
	Pro_MS_Upload_Msg6: string
	Pro_MS_Upload_Msg7: string
	Pro_MS_Upload_Btn1: string
	Pro_MS_Type_Menu1: string
	Pro_MS_Type_Menu2: string
	Pro_MS_Type_Menu3: string
	Pro_Setting_Btn1: string
	Pro_Setting_Btn2: string
	Pro_Setting_Btn3: string
	Pro_Setting_Btn4: string
	Pro_Setting_Btn5: string
	Pro_Setting_Menu1: string
	Pro_Setting_Menu2: string
	Pro_Setting_Menu3: string
	Settings: string
	Pro_Setting_Menu4: string
	Pro_Setting_Menu5: string
	Pro_Setting_Menu6: string
	Pro_Setting_Menu7: string
	Pro_Setting_Menu8: string
	Pro_Setting_Menu9: string
	Pro_Setting_Menu10: string
	Pro_Setting_Menu11: string
	Pro_Setting_Msg1: string
	Pro_Setting_Version: string
	Pro_Setting_Pwd_Menu1: string
	Pro_Setting_Pwd_Menu2: string
	Pro_Setting_Pwd_Menu3: string
	Pro_Setting_Pwd_Msg1: string
	Pro_Setting_Pwd_Msg2: string
	Pro_Tab_Topic_Title: string
	Pro_Tab_Menu1: string
	Pro_Tab_Menu2: string
	Pro_Tab_Menu3: string
	Pro_System_Msg1: string
	Pro_System_Msg2: string
	Pro_System_Msg3: string
	Pro_System_Msg4: string
	Pro_Signup_Msg1: string
	Pro_LogOut_Msg1: string
	Pro_SignIn_Content1: string
	Pro_SignIn_Btn1: string
	Pro_SignIn_Btn2: string
	Pro_SignIn_Msg1: string
	Pro_SignIn_Msg2: string
	Pro_SignIn_Msg3: string
	Pro_SignIn_Msg4: string
	Pro_SignIn_Msg5: string
	Pro_SignIn_Msg6: string
	Pro_SignIn_Msg7: string
	Pro_SignIn_Msg8: string
	Pro_SignIn_Msg9: string
	Pro_Register_Msg1: string
	Pro_Register_Msg2: string
	Pro_Alert_Msg1: string
	Pro_Alert_Msg2: string
	Pro_Alert_Msg3: string
	Pro_Alert_Msg4: string
	Pro_Alert_Msg5: string
	Pro_Alert_Msg6: string
	Pro_Alert_Msg7: string
	Pro_Alert_Msg8: string
	Pro_Alert_Msg9: string
	Pro_Alert_Msg10: string
	Pro_Alert_Msg11: string
	Pro_Alert_Msg12: string
	Pro_SearchCase_Title: string
	Pro_SearchCase_Content1: string
	Pro_Date_String: string
	Pro_Type_String: string
	Pro_Search_Btn: string
	Pro_Clear_Btn: string
	Pro_Signup_Btn1: string
	Pro_Forgot_Pwd_Msg1: string
	Pro_Forgot_Pwd_Msg2: string
	Pro_Forgot_Pwd_Msg3: string
}

/**i18多語字串：English */
export const i18nString_en: I18nString = {
	"Welcome to Dr.AI": "Welcome to Dr.AI",
	"Pro_Home_Title2": "Borderless Empowering Healthcare Professionals",
	"Get Started": "Get Started",
	"Dr.AI": "Dr.AI",
	"Today": "Today",
	"+ New Case": "+ New Case",
	"-- Case is empty --": "-- Case is empty --",
	"credits": "credits",
	"Valid until": "Valid until",
	"Logout": "Logout",
	"Admission Note": "Admission Note",
	"Progress Note": "Progress Note",
	"Pharmacotherapy": "Pharmacotherapy",
	"Cancer Strategy": "Cancer Strategy",
	"Any Questions": "Any Questions",
	"Excellent": "Excellent",
	"Good": "Good",
	"Fair": "Fair",
	"Poor": "Poor",
	"Generating. Results will be ready in 1-3 minutes.": "Generating. Results will be ready in 1-3 minutes.",
	"Regenerate": "Regenerate",
	"Refine Admission Note & Generate Admission Orders": "Refine Admission Note & Generate Admission Orders",
	"Edit": "Edit",
	"Save": "Save",
	"Please enter a Note Title using a keyword or the patient's chart number for easy search.": "Please enter a Note Title using a keyword or the patient's chart number for easy search.",
	"Admission Date": "Admission Date",
	"Note Title": "Note Title",
	"Your case note": "Your case note",
	"Title": "Title",
	"Copy": "Copy",
	"Download": "Download",
	"Save As": "Save As",
	"Rename Title": "Rename Title",
	"Edit Message": "Edit Message",
	"Your account balance is now depleted.": "Your account balance is now depleted.",
	"My Package": "My Package",
	"Cancel": "Cancel",
	"Please click on [Our plan] to access recharge functions.": "Please click on [Our plan] to access recharge functions.",
	"Are you sure you want to delete this information": "Are you sure you want to delete this information",
	"Yes": "Yes",
	"Disclaimer for Dr.AI": "Disclaimer for Dr.AI",
	"I agree": "I agree",
	"Next": "Next",
	"Introducing Dr.AI": "Introducing Dr.AI",
	"Embracing the Future of Healthcare, Combing the Best of AI Technologies": "Embracing the Future of Healthcare, Combining the Best of AI Technologies",
	"Empowering Healthcare Professionals:": "Empowering Healthcare Professionals:",
	"Unparalleled Data Analysis": "Unparalleled Data Analysis",
	"Assured Quality": "Assured Quality",
	"Proactive Health Predictions": "Proactive Health Predictions",
	"Personalized Treatments": "Personalized Treatments",
	"Collaborative Healthcare": "Collaborative Healthcare",
	"Evidence-Informed Recommendations": "Evidence-Informed Recommendations",
	"Go Back": "Go Back",
	"Please enter your email and follow the provided instructions to complete your password change.": "Please enter your email and follow the provided instructions to complete your password change.",
	"Forgot password": "Forgot password",
	"We have already sent a verification email to": "We have already sent a verification email to",
	"Email": "Email",
	"Submit": "Submit",
	"Auth_Error_Msg1": "The verification code entered is incorrect.",
	"Enter verification code": "Enter verification code",
	"Resending…": "Resending…",
	"Resend Code": "Resend Code",
	"Log In": "Log In",
	"Email Address": "Email Address",
	"Sign up": "Sign up",
	"Hello": "Hello",
	"Password": "Password",
	"Forgot password?": "Forgot password?",
	"Choose your plan": "Choose your plan",
	"Select the best plan for your needs": "Select the best plan for your needs",
	"FREE Trial": "FREE Trial",
	"USD for 3 months": "USD for 3 months",
	"Sign up and instantly receive 300 credits!": "Sign up and instantly receive 300 credits!",
	"300 credits for use": "300 credits for use",
	"3-month trial period": "3-month trial period",
	"6 months of data preservation": "6 months of data preservation",
	"Apply for Free Trial": "Apply for Free Trial",
	"Basic": "Basic",
	"USD for 1 year": "USD for 1 year",
	"Begin experimenting with 300 credits over the course of a year.": "Begin experimenting with 300 credits over the course of a year.",
	"1-year validity": "1-year validity",
	"18 months of data preservation": "18 months of data preservation",
	"Includes data recovery (available upon credit refill)": "Includes data recovery (available upon credit refill)",
	"Coming Soon": "Coming Soon",
	"Plus": "Plus",
	"For more flexibility, start experimenting with 600 credits over a one-year span.": "For more flexibility, start experimenting with 600 credits over a one-year span.",
	"600 credits for use": "600 credits for use",
	"Premium": "Premium",
	"USD per month": "USD per month",
	"Unlimited access tailored for healthcare professionals.": "Unlimited access tailored for healthcare professionals.",
	"Unlimited credits": "Unlimited credits",
	"Pro_Pack_Item6": "Limitation: 40 serial queries will pause analysis for 3 hours",
	"Biomedical Technology, Inc.": "Biomedical Technology, Inc.",
	"not found": "not found",
	"Create a New Account": "Create a New Account",
	"Male": "Male",
	"Female": "Female",
	"Set up a password": "Set up a password",
	"Confirm Password": "Confirm Password",
	"Your Detail": "Your Detail",
	"Pro_Home_Content1": "Dr.AI seamlessly integrates inpatient and outpatient care, addressing healthcare professionals' challenges. In inpatient settings, it incorporates admission notes, orders, and progress notes, alongside pharmacotherapy and cancer strategies.\n\nFor outpatient care, it features SOAP notes, nursing notes, and meeting recaps, simplifying patient interactions and automating treatment plans with diagnosis support to enhance the clinical pathway.", // 中間有換行, 記得加 '\n\n'
	"To thoroughly evaluate the patient's clinical conditions, Dr. AI needs specific information. This includes the patient's chief complaint, details of the current illness, a review of systems, the patient's past medical history, findings from the physical examination, and laboratory results. It is important to exclude any personally identifiable information, such as the patient's name or ID, from this data.": "To thoroughly evaluate the patient's clinical conditions, Dr. AI needs specific information. This includes the patient's chief complaint, details of the current illness, a review of systems, the patient's past medical history, findings from the physical examination, and laboratory results. It is important to exclude any personally identifiable information, such as the patient's name or ID, from this data.",
	"Select the appropriate Admission Note for evaluation from the left column, and provide details regarding the Subjective (S) and Objective (O) aspects of the patient's condition. This should include the patient's symptoms, observable signs, and laboratory results. Please avoid including any personally identifiable information, such as the patient's name or ID. If the sidebar does not offer any selection options, it indicates that you need to first create the patient's Admission Note before moving on to the Progress Note analysis with Dr. AI.": "Select the appropriate Admission Note for evaluation from the left column, and provide details regarding the Subjective (S) and Objective (O) aspects of the patient's condition. This should include the patient's symptoms, observable signs, and laboratory results. Please avoid including any personally identifiable information, such as the patient's name or ID. If the sidebar does not offer any selection options, it indicates that you need to first create the patient's Admission Note before moving on to the Progress Note analysis with Dr. AI.",
	"On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.": "On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.",
	"Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.": "Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.",
	"Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.": "Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.",
	"Don't have an account?": "Don't have an account?",
	"Search...": "Search...",
	"Please check your email for the 6-digit verification code and enter it in the fields below": "Please check your email for the 6-digit verification code and enter it in the fields below",
	"Chief complaint, Present illness, Review of systems, Past medical history, Physical exam, and Laboratory results": "Chief complaint, Present illness, Review of systems, Past medical history, Physical exam, and Laboratory results",
	"The Subjective and Objective elements include the patient's symptoms, clinical signs, and Laboratory data": "The Subjective and Objective elements include the patient's symptoms, clinical signs, and Laboratory data",
	"Questions regarding the patient's current medication and pertinent pharmacotherapy regimen.": "Questions regarding the patient's current medication and pertinent pharmacotherapy regimen.",
	"Details on cancer type and stage, medical history, pathology reports, genetic testing results, performance status, radiological findings, treatment history, and patient preferences": "Details on cancer type and stage, medical history, pathology reports, genetic testing results, performance status, radiological findings, treatment history, and patient preferences",
	"Raise any questions": "Raise any questions",
	"Your Email": "Your Email",
	"fill in Captcha": "fill in Captcha",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, and numbers.": "The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, and numbers.",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.": "The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.",
	"Enter password": "Enter password",
	"Inquiries concerning the patient's ongoing medication or relevant pharmacotherapy regimen.": "Inquiries concerning the patient's ongoing medication or relevant pharmacotherapy regimen.",
	"Please enter your email and follow the instructions to finish changing your password.": "Please enter your email and follow the instructions to finish changing your password.",
	"Pro_Home_Link1": "To learn more",
	"Pro_Jump_Title1": "To Sign Up for Dr.AI on Your Mobile Device",
	"Pro_Jump_Title2": "The mobile version of Dr.AI is currently under construction.",
	"Pro_Jump_Content1": "Please rotate your phone to landscape mode to sign up.\nAfter signing up on your phone, please return to your desktop to enjoy the full Dr.AI experience. We apologize for any inconvenience this may cause!",
	"Pro_Jump_Content2": "P.S. Feel free to preview the Dr.AI interface in action with our video guide.",
	Pro_Main_Menu1: "Inpatient",
	Pro_Main_Menu2: "Outpatient",
	Pro_Main_Menu3: "Medical Scribe",
	Pro_SOAP_TTL: "SOAP Note",
	Pro_SOAP_DSP: "The Subjective, Objective, Assessment, and Plan (SOAP) note is an acronym representing a widely used method of documentation for healthcare providers. In the outpatient setting, to thoroughly evaluate a patient's clinical condition, Dr.AI requires specific information. This includes the patient's symptoms, current illness, past medical history, findings from the physical examination, and laboratory results. You can also select a dialogue transcript from the list of medical scribe recordings to generate the SOAP note. Alternatively, you can continue the conversation to follow up on an already created SOAP note. It is crucial to exclude any personally identifiable information, such as the patient's name or ID, from this data.",
	Pro_SOAP_TIP: "Input the symptoms, clinical signs, laboratory data, and a direct medical dialogue transcript, or continue the conversation following the note.",
	Pro_MS_List_TTL: "List of Medical Scribes for Voice Recordings:",
	Pro_MS_List_DSP: "Please select an item from the voice recordings list for further analysis.",
	Pro_MS_List_Msg1: "No data",
	Pro_MS_Recording_TTL: "Medical Scribe Note",
	Pro_MS_Recording_Msg1: "Save before exiting?",
	Pro_Setting_Btn1: "Confirm",
	Pro_Setting_Menu1: "Current Plan",
	Pro_Setting_Menu2: "System Language",
	Pro_Setting_Menu3: "Name",
	Pro_Setting_Menu4: "Reply Language",
	Pro_Setting_Version: "Version",
	Pro_Tab_Topic_Title: "Choose Services",
	Pro_Tab_Menu1: "Medical Scribe List",
	Pro_Tab_Menu2: "Case History",
	Pro_Tab_Menu3: "Settings",
	Settings: "Settings",
	Copyright_Desc: "Copyright © 2023-2024 INTOWELL Biomedical Technology, Inc. Powered by eXpertMind",
	Pro_NursingN_DSP: "The Nursing Note function documents the care actions, nursing observations, and medical interventions carried out by nursing staff. It supports globally standardized medical record formats and can automatically generate formats such as SOAPIE, ISBAR, and DAR upon request. The comprehensive Nursing Note aids in coordinating handovers among nursing staff within the hospital, and enhances patient care and communication. It is essential to exclude any personally identifiable information.",
	Pro_NursingN_TIP: "Input the subjective and objective data, including nursing observations, care actions, and medical interventions.",
	Pro_ConsultD_DSP: "Consulting Dr. AI provides access professional guidance and advice to support clinical decision-making, thereby enhancing the quality and effectiveness of patient care. You may also engage in ongoing discussions about the patient's clinical situation to explore further clinical possibilities. Please ensure that all personally identifiable information is excluded from the data.",
	Pro_ConsultD_TIP: "Input the series of questions for comprehensive analysis.",
	Pro_MeetingR_DSP: "The Meeting Recap feature automatically generates summaries of discussions, decisions, and action items from healthcare team meetings, adhering to standard medical formats. This ensures clear communication and alignment on patient care plans and objectives. For the voice analysis of case discussion meetings, it is recommended that each case be recorded in a separate audio file.",
	Pro_MeetingR_TIP: "Please select the meeting voice recording file for analysis.",
	Pro_MS_Upload_Msg1: "Please select a supported file format.",
	Pro_MS_Upload_Msg2: "The file size limit is {uploadFileMaxSize} MB. The currently supported file formats are: {uploadFileFormats}",
	Pro_MS_Upload_Msg2_Size: "The file size limit is {uploadFileMaxSize} MB.",
	Pro_System_Msg1: "Loading…",
	Pro_System_Msg2: "Processing…",
	Pro_System_Msg3: "System is busy. Please try again later.",
	Pro_NursingN_TTL: "Nursing Note",
	Pro_ConsultD_TTL: "Consult Dr.AI",
	Pro_MeetingR_TTL: "Meeting Recap",
	Pro_Setting_Btn2: "Edit Personal Profile",
	Pro_Setting_Btn3: "More Plans",
	Pro_Setting_Btn4: "Account History",
	Pro_Setting_Btn5: "Edit",
	Pro_Setting_Menu5: "Nickname",
	Pro_Setting_Menu6: "Plan",
	Pro_Setting_Menu7: "Account Credits Balance",
	Pro_Setting_Menu8: "Expiration Date",
	Pro_Setting_Menu9: "Email Address",
	Pro_System_Msg4: "Copied!",
	Pro_Signup_Msg1: "Thank you for registering! Please check your email inbox to verify your account. Also, remember to check your spam folder to ensure you receive our email.",
	Pro_Setting_Menu10: "Language",
	Pro_Setting_Msg1: "Please select a valid image file (jpg or png).",
	Pro_LogOut_Msg1: "Are you sure you want to log out?",
	Pro_SignIn_Content1: "Or log in with",
	Pro_SignIn_Msg1: `Reminder! If this is your first time logging into this website using the "Sign in with Apple" feature, please make sure to set the email option to "Share My Email."`,
	Pro_SignIn_Msg2: `The system has detected that you selected the "Hide My Email" option. Please reset the settings and try again.`,
	Pro_SignIn_Msg3: "The system has detected that your browser is blocking pop-up windows. Please check your browser settings and try again.",
	Pro_SignIn_Msg4: "Sent",
	Pro_SignIn_Msg5: "Your email or password is incorrect. Please try again.",
	Pro_SignIn_Msg6: "Please enter your email address.",
	Pro_SignIn_Msg7: "The email was registered!",
	Pro_Register_Msg1: "The password is incorrect!",
	Pro_Register_Msg2: "Registration failed, please contact customer service!",
	Pro_Alert_Msg1: "Progress Note function is locked.",
	Pro_Alert_Msg2: "Saving the Progress Note requires a related Admission Note to be saved first.",
	Pro_Alert_Msg3: "Please fill in the title.",
	Pro_Alert_Msg4: "Only available in the Admission Note.",
	Pro_Alert_Msg5: "Only available after saving.",
	Pro_Alert_Msg6: "Refine the Admission Note is only possible after sending the message.",
	Pro_Alert_Msg7: "Please select an available service type.",
	Pro_Alert_Msg8: "Saving is only possible after receiving a reply to the message.",
	Pro_Alert_Msg9: "Regeneration is only possible after sending the message.",
	Pro_Alert_Msg10: "This field cannot be empty.",
	Pro_SearchCase_Title: "Search Case",
	Pro_SearchCase_Content1: "Services Type",
	Pro_Date_String: "Date",
	Pro_Search_Btn: "Search",
	Pro_Clear_Btn: "Clear",
	Pro_Signup_Btn1: "Resend Activation Email",
	Pro_Setting_Pwd_Menu1: "Current Password",
	Pro_Setting_Pwd_Menu2: "New Password",
	Pro_Setting_Pwd_Menu3: "Confirm New Password",
	Pro_Setting_Pwd_Msg1: "The original password you entered is incorrect. Please verify and try again!",
	Pro_Setting_Pwd_Msg2: "Password changed successfully!",
	Pro_SignIn_Msg8: "Your account can no longer be accessed. Please contact customer service!",
	Pro_Alert_Msg11: "Are you sure you want to leave this page?",
	Pro_Alert_Msg12: "Your account has been idle for too long. Please log in again!",
	Pro_MS_Type_Menu1: "All",
	Pro_MS_Type_Menu2: "Live Recording",
	Pro_MS_Type_Menu3: "File Upload",
	Pro_Type_String: "Type",
	Pro_SignIn_Btn1: "Sign in with Apple",
	Pro_SignIn_Btn2: "Sign in with Google",
	Pro_SignIn_Msg9: "Your account has not been activated. Please check your email and complete the verification or resend the activation email and try again.",
	Pro_MS_Upload_TTL: "Audio File Upload",
	Pro_MS_Upload_DSP: "The file size limit is {fileSize} MB, and the maximum file duration is {fileDuration} minutes. All common audio file formats are currently supported.",
	Pro_MS_Upload_Msg3: "Your file is too large; the maximum size allowed is {fileSize} MB.",
	Pro_MS_Upload_Msg4: "The file exceeds the maximum duration of {fileDuration} minutes.",
	Pro_MS_Upload_Msg5: "Request timed out. Please try submitting again.",
	Pro_MS_Upload_Msg6: "File uploading…",
	Pro_MS_Upload_Msg7: "Translation in progress. Please wait…",
	Pro_MS_Upload_Btn1: "File Upload",
	Pro_Setting_Menu11: "Speech Language",
	Pro_Forgot_Pwd_Msg1: "This account does not exist!",
	Pro_Forgot_Pwd_Msg2: "The verification code is correct!",
	Pro_Forgot_Pwd_Msg3: "The verification code entered is incorrect. Please try again!"
}

/**i18多語字串：繁體中文 */
export const i18nString_tw: I18nString = {
	"Welcome to Dr.AI": "歡迎造訪Dr.AI",
	"Pro_Home_Title2": "無疆界支援醫療專業人員",
	"Get Started": "開始使用",
	"Dr.AI": "Dr.AI",
	"Today": "今天",
	"+ New Case": "+ 新病例",
	"-- Case is empty --": "-- 目前無病例 --",
	"credits": "點數",
	"Valid until": "有效期至",
	"Logout": "登出",
	"Admission Note": "入院記錄",
	"Progress Note": "病程記錄",
	"Pharmacotherapy": "藥物治療",
	"Cancer Strategy": "癌症治療策略",
	"Any Questions": "任何問題",
	"Excellent": "優",
	"Good": "好",
	"Fair": "普通",
	"Poor": "差",
	"Generating. Results will be ready in 1-3 minutes.": "分析中…請稍候1-3分鐘",
	"Regenerate": "重新生成",
	"Refine Admission Note & Generate Admission Orders": "優化入院記錄並生成入院醫囑",
	"Edit": "編輯",
	"Save": "保存",
	"Please enter a Note Title using a keyword or the patient's chart number for easy search.": "請輸入標題，可使用關鍵字或病例號以便於搜尋",
	"Admission Date": "入院日期",
	"Note Title": "標題",
	"Your case note": "您的病例記錄",
	"Title": "標題",
	"Copy": "複製",
	"Download": "下載",
	"Save As": "另存為",
	"Rename Title": "重新命名標題",
	"Edit Message": "編輯訊息",
	"Your account balance is now depleted.": "您的帳戶餘額已用完",
	"My Package": "我的方案",
	"Cancel": "取消",
	"Please click on [Our plan] to access recharge functions.": "請點擊[我們的方案]以切換到儲值功能",
	"Are you sure you want to delete this information": "您確定要刪除此訊息嗎?",
	"Yes": "是",
	"Disclaimer for Dr.AI": "Dr.AI 免責聲明",
	"I agree": "我同意",
	"Next": "下一步",
	"Introducing Dr.AI": "關於Dr.AI",
	"Embracing the Future of Healthcare, Combing the Best of AI Technologies": "擁抱明日醫療 結合最先進的人工智慧技術",
	"Empowering Healthcare Professionals:": "賦能醫療照護專家：",
	"Unparalleled Data Analysis": "無與倫比的數據分析能力",
	"Assured Quality": "質量保證",
	"Proactive Health Predictions": "主動的健康預測",
	"Personalized Treatments": "個性化治療",
	"Collaborative Healthcare": "協同醫療照護",
	"Evidence-Informed Recommendations": "基於實證的建議",
	"Go Back": "返回",
	"Please enter your email and follow the provided instructions to complete your password change.": "請輸入您的電子郵件並按照說明完成更改密碼",
	"Forgot password": "忘記密碼",
	"We have already sent a verification email to": "我們已經發送了一封驗證郵件到",
	"Email": "電子郵件",
	"Submit": "提交",
	"Auth_Error_Msg1": "輸入的驗證碼不正確",
	"Enter verification code": "輸入驗證碼",
	"Resending…": "重新發送",
	"Resend Code": "重新發送驗證碼",
	"Log In": "登錄",
	"Email Address": "電子郵件地址",
	"Sign up": "註冊",
	"Hello": "您好",
	"Password": "密碼",
	"Forgot password?": "忘記密碼？",
	"Choose your plan": "選擇您的方案",
	"Select the best plan for your needs": "選擇最適合您需求的方案",
	"FREE Trial": "免費試用",
	"USD for 3 months": " USD 為期3個月",
	"Sign up and instantly receive 300 credits!": "立即註冊並獲得300個點數！",
	"300 credits for use": "可使用300個點數",
	"3-month trial period": "3個月試用期",
	"6 months of data preservation": "6個月的數據保存",
	"Apply for Free Trial": "申請免費試用",
	"Basic": "基礎版",
	"USD for 1 year": "USD 為期1年",
	"Begin experimenting with 300 credits over the course of a year.": "一年內可使用300個點數體驗",
	"1-year validity": "1年有效期",
	"18 months of data preservation": "18個月的數據保存",
	"Includes data recovery (available upon credit refill)": "包括數據恢復（於點數充值時同步恢復）",
	"Coming Soon": "即將推出",
	"Plus": "進階版",
	"For more flexibility, start experimenting with 600 credits over a one-year span.": "更大的靈活性，一年內可使用600個點數體驗",
	"600 credits for use": "可使用600個點數",
	"Premium": "頂級版",
	"USD per month": "USD 月定制",
	"Unlimited access tailored for healthcare professionals.": "為醫療專家量身打造的海量分析",
	"Unlimited credits": "無限制點數",
	"Pro_Pack_Item6": "限制：40個連續使用查詢將暫停分析3小時",
	"Biomedical Technology, Inc.": "Biomedical Technology, Inc.",
	"not found": "未找到",
	"Create a New Account": "創建新帳戶",
	"Male": "男性",
	"Female": "女性",
	"Set up a password": "設置密碼",
	"Confirm Password": "確認密碼",
	"Your Detail": "您的詳情",
	"Pro_Home_Content1": "Dr.AI 無縫整合住院及門診照護，解決醫療專業人員的挑戰。在住院照護中，其整合了入院記錄、醫囑和病程記錄，並結合藥物治療和癌症治療策略。\n\n在門診照護上，搭載了SOAP記錄、護理記錄、會議摘要，簡化醫病互動並自動生成治療計劃，強化臨床途徑，提供輔助診斷的支援。", // 中間有換行, 記得加 '\n\n'
	"To thoroughly evaluate the patient's clinical conditions, Dr. AI needs specific information. This includes the patient's chief complaint, details of the current illness, a review of systems, the patient's past medical history, findings from the physical examination, and laboratory results. It is important to exclude any personally identifiable information, such as the patient's name or ID, from this data.": "為了全面評估病人的臨床狀況，Dr. AI 需要具體的訊息，這包括病人的主訴、目前疾病的詳細訊息、系統回顧、病人的過往病史、身體檢查的發現，以及實驗室結果。重要的是輸入資料一定要排除任何個人身份資訊，如患者的姓名或身份證號碼",
	"Select the appropriate Admission Note for evaluation from the left column, and provide details regarding the Subjective (S) and Objective (O) aspects of the patient's condition. This should include the patient's symptoms, observable signs, and laboratory results. Please avoid including any personally identifiable information, such as the patient's name or ID. If the sidebar does not offer any selection options, it indicates that you need to first create the patient's Admission Note before moving on to the Progress Note analysis with Dr. AI.": "從左欄選擇適當的入院記錄以進行評估，並提供有關病人的主觀（S）和客觀（O）方面的詳細病況。這應包括病人的症狀、可觀察到的徵象和實驗室結果。請避免輸入任何個人身份識別資料，如病人的名字或身份證號。如果側欄沒有提供任何選擇項目，這表示您需要先創建病人的入院記錄，然後再進行 Dr. AI 的病程分析",
	"On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.": "在 Dr. AI 平台上，醫療照護專家可以使用一個先進而全面的藥物資料庫。這個先進的系統能夠快速且準確地檢索包括副作用、監測參數、不良事件和藥品注意事項在內的各種訊息。此外，您可以從左側欄中選擇相關的入院記錄進行評估，並輸入與病人的藥物治療相關的問題。請勿輸入任何個人身份識別資料，如病人的名字或身份證號。如果側欄中沒有可選擇的選項，這表示您需要先創建病人的入院記錄，然後再進行 Dr. AI 的藥物治療分析",
	"Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.": "產生癌症治療計劃需要全面的病患狀況。應提供必要的數據，如仔細的醫療史、癌症的類型和分期、病理報告、基因檢測結果、病情狀態、放射學報告、先前的治療史，以及病人的偏好。此外，您可以從側欄中選擇適當的入院記錄進行合併分析。請確保不包括任何個人身份識別資訊，如病人的名字或身份證號，這一點極為重要。如果側欄沒有顯示任何可選擇的項目，這表示您的首要步驟是創建病人的入院記錄，然後再與 Dr. AI 一起制定癌症治療策略",
	"Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.": "Dr. AI 被用來監測病人在住院的不同階段可能產生的健康問題，包括入院時、住院期間、出院時、以及出院後。歡迎隨時提出任何問題，此外您可以從左側欄中選擇適當的入院記錄進行分析。記得要排除個人識別資料，如病人的名字或身份證號。如果您在側欄中看不到任何選項，這表示您應該先創建病人的入院記錄，然後再進行與Dr. AI的問答交流",
	"Don't have an account?": "沒有帳戶嗎？",
	"Search...": "搜尋...",
	"Please check your email for the 6-digit verification code and enter it in the fields below": "請至您的Email信箱收取6位數驗證碼，並填入下面欄位",
	"Chief complaint, Present illness, Review of systems, Past medical history, Physical exam, and Laboratory results": "主訴、現在病況、系統回顧、過去病史、身體檢查、及實驗室結果",
	"The Subjective and Objective elements include the patient's symptoms, clinical signs, and Laboratory data": "主觀和客觀要素，包括病人的症狀、臨床徵象和實驗室數據",
	"Questions regarding the patient's current medication and pertinent pharmacotherapy regimen.": "有關病人目前用藥和相關藥物治療計劃的問題",
	"Details on cancer type and stage, medical history, pathology reports, genetic testing results, performance status, radiological findings, treatment history, and patient preferences": "關於癌症類型和分期、醫療病史、病理報告、遺傳檢測結果、病情狀態、放射學發現、治療史，以及病人偏好",
	"Raise any questions": "提出任何問題",
	"Your Email": "您的電子郵件",
	"fill in Captcha": "填寫驗證碼",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, and numbers.": "密碼應至少包含8個字符，並包含大寫和小寫字母以及數字。",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.": "密碼應至少包含8個字符，並包含大寫和小寫字母、數字和特殊字符。",
	"Enter password": "輸入密碼",
	"Inquiries concerning the patient's ongoing medication or relevant pharmacotherapy regimen.": "有關患者正在進行的藥物治療或相關藥物治療方案的詢問。",
	"Please enter your email and follow the instructions to finish changing your password.": "請輸入您的電子郵件並按照指示完成密碼變更",
	"Pro_Home_Link1": "了解更多",
	"Pro_Jump_Title1": "使用手機註冊 Dr.AI 注意事項",
	"Pro_Jump_Title2": "Dr.AI 『手機版』目前尚在建置中",
	"Pro_Jump_Content1": "請將手機轉成『橫向操作』以便進行註冊。\n手機註冊完成後，再請回到桌機來體驗Dr.AI。造成您的不便 敬請見諒!",
	"Pro_Jump_Content2": "P.S. 歡迎預覽Dr.AI介面操作情境影片",
	Pro_Main_Menu1: "住院病患",
	Pro_Main_Menu2: "門診病患",
	Pro_Main_Menu3: "醫療書寫員",
	Pro_SOAP_TTL: "SOAP記錄",
	Pro_SOAP_DSP: "主觀、客觀、評估和計劃（SOAP）記錄是醫療專業人員廣泛使用的記錄方法。在門診環境中，為了徹底評估病患的臨床狀況，Dr.AI 需要特定的資訊，包括病患的症狀、現在病情、過去的病史、身體檢查和實驗室檢查結果。您也可以從醫療書寫員錄音列表中選擇對話記錄來生成SOAP記錄；或者您也可以用已創建的SOAP記錄來繼續對話。請您注意務必從數據中排除任何可識別個人身份的訊息，如病患的姓名或ID。",
	Pro_SOAP_TIP: "請輸入症狀、臨床徵象、實驗室數據和直接的醫療對話記錄，或根據原本的記錄繼續對話。",
	Pro_MS_List_TTL: "醫療書寫員的語音列表：",
	Pro_MS_List_DSP: "請從語音列表中選擇項目以進行進一步分析",
	Pro_MS_List_Msg1: "無資料",
	Pro_MS_Recording_TTL: "醫療書寫員記錄",
	Pro_MS_Recording_Msg1: "您要儲存後離開嗎？",
	Pro_Setting_Btn1: "確認",
	Pro_Setting_Menu1: "目前方案",
	Pro_Setting_Menu2: "系統語言",
	Pro_Setting_Menu3: "姓名",
	Pro_Setting_Menu4: "回覆語言",
	Pro_Setting_Version: "版本",
	Pro_Tab_Topic_Title: "選擇服務",
	Pro_Tab_Menu1: "醫療書寫員列表",
	Pro_Tab_Menu2: "歷史病例",
	Pro_Tab_Menu3: "設定",
	Settings: "設定",
	Pro_NursingN_DSP: "護理記錄的功能是記錄護理人員所執行的照護行動、護理觀察與醫療介入。並搭載全球標準的醫療記錄格式，可依據您的指令自動生成 SOAPIE、ISBAR 和 DAR 的格式，完整的護理記錄可輔助院內護理人員交接的協調性，以及患者的照護與溝通。請您注意務必從數據中排除任何可識別個人身份的訊息。",
	Pro_NursingN_TIP: "請輸入主觀和客觀資料，包括護理觀察、照護行動和醫療介入",
	Pro_ConsultD_DSP: "諮詢Dr.AI的功能可尋求Dr.AI的專業指導和建議，以支援門診會診中的臨床決策，並提高患者照護的品質和成效。您也可以依據病患的臨床情況提出連續性的問題來討論，以了解更多臨床的可能性。請您注意務必從數據中排除任何可識別個人身份的訊息。",
	Pro_ConsultD_TIP: "請輸入一系列問題以進行全方位分析",
	Pro_MeetingR_DSP: "會議摘要功能是根據標準的醫療格式自動生成醫療團隊會議中的討論、決策和行動項目的摘要，確保醫療團隊的病患照護計劃和目標保持一致。若為病例討論會議之語音分析，建議每位病例獨立一個音檔記錄。",
	Pro_MeetingR_TIP: "請選擇會議語音錄音檔案以供分析",
	Pro_MS_Upload_Msg1: "請選擇支援的檔案格式",
	Pro_MS_Upload_Msg2: "檔案容量上限為 {uploadFileMaxSize} MB，目前支援的檔案格式：{uploadFileFormats}",
	Pro_MS_Upload_Msg2_Size: "檔案容量上限為 {uploadFileMaxSize} MB",
	Pro_System_Msg1: "載入中…",
	Pro_System_Msg2: "處理中…",
	Pro_System_Msg3: "系統忙碌中，請稍後再試",
	Pro_NursingN_TTL: "護理記錄",
	Pro_ConsultD_TTL: "諮詢Dr.AI",
	Pro_MeetingR_TTL: "會議摘要",
	Pro_Setting_Btn2: "編輯個人資訊",
	Pro_Setting_Btn3: "更多方案",
	Pro_Setting_Btn4: "帳戶明細",
	Pro_Setting_Btn5: "編輯",
	Pro_Setting_Menu5: "暱稱",
	Pro_Setting_Menu6: "方案",
	Pro_Setting_Menu7: "帳戶點數餘額",
	Pro_Setting_Menu8: "到期日",
	Pro_Setting_Menu9: "電子郵件",
	Pro_System_Msg4: "已複製！",
	Pro_Signup_Msg1: "感謝您註冊！請查看您的電子郵件信箱以進行帳戶驗證。同時，請記得檢查垃圾信箱，以確保您能夠收到我們的郵件。",
	Pro_Setting_Menu10: "語言",
	Pro_Setting_Msg1: "請選擇有效的圖片文件 (jpg 或 png)",
	Pro_LogOut_Msg1: "您確定要登出嗎？",
	Pro_SignIn_Content1: "或以此方式登入",
	Pro_SignIn_Msg1: `提醒您！若為首次透過「使用 Apple 帳號登入」功能登入本網站，請務必將電子郵件選項設定為「分享我的電子郵件」。`,
	Pro_SignIn_Msg2: `系統已偵測到您選擇了「隱藏電子郵件地址」選項，請重置設定後並再試一次。`,
	Pro_SignIn_Msg3: "系統已偵測到瀏覽器阻擋彈出視窗顯示，請確認瀏覽器設定後並再試一次。",
	Pro_SignIn_Msg4: "已發送",
	Pro_SignIn_Msg5: "您的電子郵件或密碼不正確。請再試一次。",
	Pro_SignIn_Msg6: "請輸入您的電子郵件地址",
	Pro_SignIn_Msg7: "電子郵件已註冊！",
	Pro_Register_Msg1: "密碼錯誤！",
	Pro_Register_Msg2: "註冊失敗，請聯繫客服人員！",
	Pro_Alert_Msg1: "病程記錄功能鎖定中",
	Pro_Alert_Msg2: "病程記錄保存需要先存在相關之入院紀錄",
	Pro_Alert_Msg3: "請填寫標題",
	Pro_Alert_Msg4: "僅在入院紀錄中可用",
	Pro_Alert_Msg5: "僅在保存後可用",
	Pro_Alert_Msg6: "發送訊息後才能優化入院記錄",
	Pro_Alert_Msg7: "請選擇可用的服務類型",
	Pro_Alert_Msg8: "待訊息回覆後才能保存",
	Pro_Alert_Msg9: "發送訊息後才能重新生成",
	Pro_Alert_Msg10: "該欄位不能為空",
	Pro_SearchCase_Title: "搜尋病例",
	Pro_SearchCase_Content1: "服務類型",
	Pro_Date_String: "日期",
	Pro_Search_Btn: "搜尋",
	Pro_Clear_Btn: "清除",
	Pro_Signup_Btn1: "重送啟用信",
	Pro_Setting_Pwd_Menu1: "原密碼",
	Pro_Setting_Pwd_Menu2: "新密碼",
	Pro_Setting_Pwd_Menu3: "再次輸入新密碼",
	Pro_Setting_Pwd_Msg1: "原密碼輸入錯誤，請您確認後再試一次！",
	Pro_Setting_Pwd_Msg2: "密碼變更成功！",
	Pro_SignIn_Msg8: "您的帳戶已無法登入，請聯繫客服人員！",
	Pro_Alert_Msg11: "您確定要離開此頁面嗎？",
	Pro_Alert_Msg12: "您的帳號已閒置逾時，請您重新登入！",
	Pro_MS_Type_Menu1: "全部",
	Pro_MS_Type_Menu2: "錄音",
	Pro_MS_Type_Menu3: "上傳",
	Pro_Type_String: "類型",
	Pro_SignIn_Btn1: "使用Apple帳號登入",
	Pro_SignIn_Btn2: "使用Google帳號登入",
	Pro_SignIn_Msg9: "您的帳戶尚未啟用，請檢查 Email 信箱並完成認證或重送啟用信後再試一次。",
	Pro_MS_Upload_TTL: "音檔上傳",
	Pro_MS_Upload_DSP: "檔案大小限制為 {fileSize} MB，檔案時長限制為 {fileDuration} 分鐘。目前支援所有常見的音訊檔案格式。",
	Pro_MS_Upload_Msg3: "您的檔案過大，最大允許大小為 {fileSize} MB。",
	Pro_MS_Upload_Msg4: "檔案時長超過了 {fileDuration} 分鐘的限制。",
	Pro_MS_Upload_Msg5: "請求超時，請再次提交。",
	Pro_MS_Upload_Msg6: "檔案上傳中…",
	Pro_MS_Upload_Msg7: "翻譯進行中，請稍候…",
	Pro_MS_Upload_Btn1: "上傳",
	Pro_Setting_Menu11: "語音語言",
	Pro_Forgot_Pwd_Msg1: "此帳號不存在！",
	Pro_Forgot_Pwd_Msg2: "驗證碼正確！",
	Pro_Forgot_Pwd_Msg3: "您所輸入的驗證碼不正確，請再試一次！"
}

/**i18多語字串：日本語 */
export const i18nString_jp: I18nString = {
	"Welcome to Dr.AI": "Dr.AIへようこそ",
	"Pro_Home_Title2": "無境界で医療専門家をサポート",
	"Get Started": "はじめる",
	"Dr.AI": "Dr.AI",
	"Today": "今日",
	"+ New Case": "+ 新しいケース",
	"-- Case is empty --": "-- 現在、ケースがありません--",
	"credits": "クレジット",
	"Valid until": "有効期限",
	"Logout": "ログアウト",
	"Admission Note": "入院記録",
	"Progress Note": "経過記録",
	"Pharmacotherapy": "薬物治療",
	"Cancer Strategy": "がん戦略",
	"Any Questions": "ご質問はありますか",
	"Excellent": "大変良い",
	"Good": "良い",
	"Fair": "普通",
	"Poor": "悪い",
	"Generating. Results will be ready in 1-3 minutes.": "生成中です。1-3分かかりますので、今しばらくお待ちください。",
	"Regenerate": "再生成",
	"Refine Admission Note & Generate Admission Orders": "入院記録を最適化して入院オーダーを生成",
	"Edit": "編集",
	"Save": "保存",
	"Please enter a Note Title using a keyword or the patient's chart number for easy search.": "簡単な検索のため、キーワードまたは患者のケース番号を使用してノートタイトルを入力してください。",
	"Admission Date": "入院日",
	"Note Title": "ノートタイトル",
	"Your case note": "あなたのケースノート",
	"Title": "タイトル",
	"Copy": "コピー",
	"Download": "ダウンロード",
	"Save As": "名前をつけて保存",
	"Rename Title": "タイトルの名前を変更",
	"Edit Message": "メッセージを編集",
	"Your account balance is now depleted.": "アカウント残高は使い果たされました。",
	"My Package": "私のプラン",
	"Cancel": "キャンセル",
	"Please click on [Our plan] to access recharge functions.": "リチャージ機能にアクセスするには[私たちのプラン]をクリックしてください。",
	"Are you sure you want to delete this information": "この情報を削除してもよろしいですか？",
	"Yes": "はい",
	"Disclaimer for Dr.AI": "Dr.AIの免責事項",
	"I agree": "同意する",
	"Next": "次へ",
	"Introducing Dr.AI": "Dr.AIについて",
	"Embracing the Future of Healthcare, Combing the Best of AI Technologies": "医療の未来を拓く、最先端AI技術を統合",
	"Empowering Healthcare Professionals:": "医療専門家を強化する：",
	"Unparalleled Data Analysis": "比類のないデータ分析能力",
	"Assured Quality": "品質保証",
	"Proactive Health Predictions": "積極的な健康予測",
	"Personalized Treatments": "個別化された治療",
	"Collaborative Healthcare": "協力的な医療",
	"Evidence-Informed Recommendations": "根拠に基づくアドバイス",
	"Go Back": "戻る",
	"Please enter your email and follow the provided instructions to complete your password change.": "パスワードの変更を完了するために、メールアドレスを入力して指示に従ってください。",
	"Forgot password": "パスワードをお忘れですか",
	"We have already sent a verification email to": "確認メールを送信しました",
	"Email": "メール",
	"Submit": "認証する",
	"Auth_Error_Msg1": "入力されたコードが正しくありません",
	"Enter verification code": "認証コードを入力してください",
	"Resending…": "再送信中",
	"Resend Code": "認証コードを再送信する",
	"Log In": "ログイン",
	"Email Address": "メールアドレス",
	"Sign up": "登録",
	"Hello": "こんにちは",
	"Password": "パスワード",
	"Forgot password?": "パスワードをお忘れですか？",
	"Choose your plan": "プランを選択する",
	"Select the best plan for your needs": "ご自身のニーズに最適なプランを選択してください",
	"FREE Trial": "無料トライアル",
	"USD for 3 months": "3ヶ月間のUSD",
	"Sign up and instantly receive 300 credits!": "今すぐ登録して300ポイントを獲得！",
	"300 credits for use": "300クレジット利用可能",
	"3-month trial period": "3ヶ月間のトライアル期間",
	"6 months of data preservation": "6ヶ月間のデータ保存",
	"Apply for Free Trial": "無料トライアルを申し込む",
	"Basic": "ベーシック",
	"USD for 1 year": "1年間のUSD",
	"Begin experimenting with 300 credits over the course of a year.": "1年間で300クレジットを使って体験する",
	"1-year validity": "1年間の有効期間",
	"18 months of data preservation": "18ヶ月間のデータ保存",
	"Includes data recovery (available upon credit refill)": "データ復旧含む（クレジットをチャージすると同時に復元される）",
	"Coming Soon": "近日公開",
	"Plus": "プラス",
	"For more flexibility, start experimenting with 600 credits over a one-year span.": "1年間で600クレジットを使ってより柔軟に体験する",
	"600 credits for use": "600クレジット利用可能",
	"Premium": "プレミアム",
	"USD per month": "月額のUSD",
	"Unlimited access tailored for healthcare professionals.": "医療専門家のためにカスタマイズされた無制限プラン",
	"Unlimited credits": "無制限クレジット",
	"Pro_Pack_Item6": "制限事項：40回連続して検索すると、分析が3時間中断される",
	"Biomedical Technology, Inc.": "バイオメディカルテクノロジー株式会社",
	"not found": "見つかりません",
	"Create a New Account": "新規アカウントを作成する",
	"Male": "男性",
	"Female": "女性",
	"Set up a password": "パスワードを設定する",
	"Confirm Password": "パスワードを確認する",
	"Your Detail": "詳細",
	"Pro_Home_Content1": "Dr.AI は入院患者と外来患者のケアをシームレスに統合し、医療関係者の問題点を解決します。入院患者ケアについて、薬物療法やがん戦略に加えて、入院記録、医師の指示、経過記録も組み込まれます。\n\n外来診療の場合、SOAP 記録、看護記録、会議の概要を備え、医師と患者のやり取りを簡素化し、治療計画を自動的に生成します。また、補助的な診断サポートを提供することで、クリニカルパスが強化されます。", // 中間有換行, 記得加 '\n\n'
	"To thoroughly evaluate the patient's clinical conditions, Dr. AI needs specific information. This includes the patient's chief complaint, details of the current illness, a review of systems, the patient's past medical history, findings from the physical examination, and laboratory results. It is important to exclude any personally identifiable information, such as the patient's name or ID, from this data.": "患者の臨床状態を徹底的に評価するために、特定の情報が必要です。患者の主訴、現在の病気の詳細、システムレビュー、患者の既往病歴、身体検査の所見、および検査結果が含まれます。患者の名前やIDなど個人を特定できる情報の入力を避けることが重要です。  ",
	"Select the appropriate Admission Note for evaluation from the left column, and provide details regarding the Subjective (S) and Objective (O) aspects of the patient's condition. This should include the patient's symptoms, observable signs, and laboratory results. Please avoid including any personally identifiable information, such as the patient's name or ID. If the sidebar does not offer any selection options, it indicates that you need to first create the patient's Admission Note before moving on to the Progress Note analysis with Dr. AI.": "左の列から適切な入院記録を選択し、患者の主観的（S）および客観的（O）状態に関する詳細を提供してください。患者の症状、観察可能な兆候、および検査結果が含まれるべきです。患者の名前やIDなどの個人を特定できる情報の入力を避けてください。サイドバーに選択オプションがない場合、 Dr. AIで経過記録分析に移る前にまず患者の入院記録を作成する必要があることを示しています。",
	"On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.": "Dr. AIプラットフォームを通じて、医療専門家は先進的で包括的な薬剤データベースにアクセスすることができます。副作用、モニタリングパラメータ、有害事象、薬剤の注意事項などの幅広い情報を迅速かつ正確に取得可能です。さらに、左側のサイドバーから関連入院記録を選択して評価を行い、患者の薬物治療に関連する質問を入力できます。患者の名前やIDなど、個人を特定できる情報の入力は避けてください。サイドバーで選択オプションがない場合、 Dr. AIで薬物療法分析に移る前にまず患者の入院記録を作成する必要があることを示しています。",
	"Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.": "がん治療計画の作成には患者の詳細な情報が必要です。詳細な医療履歴、がんの種類とステージ、病理報告、遺伝子検査の結果、パフォーマンスステータス、放射線報告、以前の治療歴、患者の好みなどのデータを提供すべきです。また、サイドバーから適切な入院記録を選択してレビューを行うこともできます。患者の名前やIDなどの個人を特定する情報を含めないでください。サイドバーに選択オプションがない場合、がん治療戦略を立てる前にまず患者の入院記録を作成する必要があることを示しています。",
	"Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.": "Dr. AIは、入院時、入院中、退院時、退院後の異なる段階で、幅広い患者の健康要因をモニタリングできるよう設計されています。ご質問があれば、気軽にお申し付けください。また、左のサイドバーから適切な入院記録を選択して分析を行うことができます。患者の名前やIDなどの個人情報を含めないようにしてください。サイドバーに選択オプションが表ない場合は、Q&Aセッションに移る前にまず患者の入院記録を作成する必要があると示しています。",
	"Don't have an account?": "アカウントをお持ちではありませんか？",
	"Search...": "検索...",
	"Please check your email for the 6-digit verification code and enter it in the fields below": "メールで6桁のコードを確認し、下記の欄に入力してください",
	"Chief complaint, Present illness, Review of systems, Past medical history, Physical exam, and Laboratory results": "主訴、現在の病気、システムレビュー、既往病歴、身体検査、検査結果",
	"The Subjective and Objective elements include the patient's symptoms, clinical signs, and Laboratory data": "主観的および客観的要素には、患者の症状、臨床徴候、および検査データが含まれます",
	"Questions regarding the patient's current medication and pertinent pharmacotherapy regimen.": "現在の薬物治療と関連薬物治療計画に関する質問",
	"Details on cancer type and stage, medical history, pathology reports, genetic testing results, performance status, radiological findings, treatment history, and patient preferences": "がんの種類とステージ、医療履歴、病理報告書、遺伝子検査結果、パフォーマンスステータス、放射線学的所見、治療歴、患者の好みに関する詳細",
	"Raise any questions": "質問する",
	"Your Email": "メール",
	"fill in Captcha": "認証コードを入力してください",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, and numbers.": "パスワードは最小8文字で、大文字と小文字の文字、数字を含む必要があります。",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.": "パスワードは最小8文字で、大文字と小文字の文字、数字、特殊文字を含む必要があります。",
	"Enter password": "パスワードを入力してください",
	"Inquiries concerning the patient's ongoing medication or relevant pharmacotherapy regimen.": "進行中の薬物治療または関連薬物治療計画に関する質問",
	"Please enter your email and follow the instructions to finish changing your password.": "パスワードの変更を完了するために、メールアドレスを入力し、指示に従ってください。",
	"Pro_Home_Link1": "もっと知る",
	"Pro_Jump_Title1": "携帯電話でDr.AIに登録する",
	"Pro_Jump_Title2": "只今Dr.AIのモバイル版は開発中です。",
	"Pro_Jump_Content1": "登録を進めるためには、携帯電話を横向きにしてください。\n携帯での登録が完了した後、デスクトップに戻り、完全なDr.AIを体験してください。ご不便をおかけして申し訳ございません！",
	"Pro_Jump_Content2": "P.S. Dr.AIのインターフェース操作動画ガイドをプレビューすることができます。",
	Pro_Main_Menu1: "入院患者",
	Pro_Main_Menu2: "外来患者",
	Pro_Main_Menu3: "メディカルクラーク",
	Pro_SOAP_TTL: "SOAP記録",
	Pro_SOAP_DSP: "主観、客観、評価、計画（SOAP）記録は、多くの医療関係者が広く使用している記載方法です。外来患者の臨床状態を徹底的に評価するために、Dr.AIは特定の情報が必要とします。患者の症状、現病歴、既往歴、身体検査の結果、および実験室の結果が含まれます。また、医療記録係の録音リストから対話記録を選択してSOAPを生成することも可能です。さらに、すでに作成されたSOAP記録に基づいて会話を続けることもできます。データから患者の名前やIDなど、個人を特定できる情報を除外してください。",
	Pro_SOAP_TIP: "症状、臨床的徴候、実験室データ、直接的な医療対話記録を入力する、或いは記録に基づいて会話を続けてください。",
	Pro_MS_List_TTL: "メディカルクラークの音声記録リスト：",
	Pro_MS_List_DSP: "さらなる分析のために、音声記録リストから項目を選択してください。",
	Pro_MS_List_Msg1: "資料なし",
	Pro_MS_Recording_TTL: "メディカルクラークの記録",
	Pro_MS_Recording_Msg1: "保存して終了しますか?",
	Pro_Setting_Btn1: "確認",
	Pro_Setting_Menu1: "現在のプラン",
	Pro_Setting_Menu2: "システム言語",
	Pro_Setting_Menu3: "お名前",
	Pro_Setting_Menu4: "返事言語",
	Pro_Setting_Version: "バージョン",
	Pro_Tab_Topic_Title: "サービスの選択",
	Pro_Tab_Menu1: "メディカルクラークリスト",
	Pro_Tab_Menu2: "過去の病歴",
	Pro_Tab_Menu3: "設定",
	Settings: "設定",
	Pro_NursingN_DSP: "看護記録は看護スタッフが行うケア行動、看護観察、および医療介入を記録する機能です。そして、グローバル適用の医療記録フォーマットを採用し、SOAPIE、ISBAR、DARのフォーマットをご指示に基づき自動生成できます。包括的な看護記録は、病院内の看護スタッフの引継ぎの調整を助け、患者のケアとコミュニケーションを補助します。個人を特定できる情報はデータから必ず除外してください。",
	Pro_NursingN_TIP: "主観的および客観的なデータを入力してください。これには、看護観察、ケア行動、医療介入が含まれます",
	Pro_ConsultD_DSP: "Dr.AIの相談機能を使って、臨床判断をサポートし、患者ケアの品質と成果を向上させるためのDr.AIの専門的なガイダンスとアドバイスを求めることができます。臨床の可能性をさらに理解するため、患者の臨床状況に基づいて連続する問題を提起して議論することも可能です。個人を特定できる情報はデータから必ず除外してください。",
	Pro_ConsultD_TIP: "全面的な分析のために一連の問題を入力してください",
	Pro_MeetingR_DSP: "会議要約機能は医療チーム会議の議論、決定、およびアクションアイテムの概要を標準の医療フォーマットに基づいて自動生成するもので、医療チームの患者ケア計画と目標が一致するように確保します。症例討議会の音声分析の場合、各症例ごとに独立した音声ファイルで記録することをお勧めします。",
	Pro_MeetingR_TIP: "分析する会議の音声録音ファイルを選択してください",
	Pro_MS_Upload_Msg1: "サポートされているファイル形式を選択してください",
	Pro_MS_Upload_Msg2: "ファイルサイズの制限は {uploadFileMaxSize} MB です。現在サポートされているファイル形式は以下のとおりです：{uploadFileFormats}",
	Pro_MS_Upload_Msg2_Size: "ファイルサイズの制限は {uploadFileMaxSize} MB です",
	Pro_System_Msg1: "読み込み中…",
	Pro_System_Msg2: "処理中…",
	Pro_System_Msg3: "混雑中です。後でもう一度お試しください。",
	Pro_NursingN_TTL: "看護記録",
	Pro_ConsultD_TTL: "Dr.AI相談",
	Pro_MeetingR_TTL: "会議要約",
	Pro_Setting_Btn2: "個人情報の編集",
	Pro_Setting_Btn3: "より多くのオプション",
	Pro_Setting_Btn4: "アカウント詳細",
	Pro_Setting_Btn5: "編集",
	Pro_Setting_Menu5: "ニックネーム",
	Pro_Setting_Menu6: "プラン",
	Pro_Setting_Menu7: "クレジット残高",
	Pro_Setting_Menu8: "有効期限",
	Pro_Setting_Menu9: "メールアドレス  ",
	Pro_System_Msg4: "コピー済み",
	Pro_Signup_Msg1: "ご登録ありがとうございます！アカウントを認証するために、メールボックスを確認してください。また、迷惑メールフォルダも確認して、メールを確実に受信できるようにしてください。",
	Pro_Setting_Menu10: "言語",
	Pro_Setting_Msg1: "有効な画像ファイル（jpg または png）を選択してください",
	Pro_LogOut_Msg1: "ログアウトしてもよろしいですか?",
	Pro_SignIn_Content1: "他のアカウントでログイン",
	Pro_SignIn_Msg1: `「Apple アカウントでサインイン」機能を使用してこのサイトに初めてログインする場合、必ずメール オプションを「メールを共有」に設定してください。`,
	Pro_SignIn_Msg2: `「メールを非公開」オプションが選択されたことが検出されました。設定をリセットして再試行してください。`,
	Pro_SignIn_Msg3: "ブラウザがポップアップ 表示をブロックしていることが検出されました。ブラウザの設定を確認して、もう一度お試しください。",
	Pro_SignIn_Msg4: "送信済",
	Pro_SignIn_Msg5: "メールアドレスまたはパスワードが正しくありません。もう一度お試しください",
	Pro_SignIn_Msg6: "メールアドレスを入力してください",
	Pro_SignIn_Msg7: "メールアドレスが既に登録されました！",
	Pro_Register_Msg1: "パスワードが正しくありません！",
	Pro_Register_Msg2: "登録に失敗しました。カスタマー サービスにお問い合わせください。",
	Pro_Alert_Msg1: "経過記録機能はロックされています",
	Pro_Alert_Msg2: "経過記録を保存するには、関連する入院記録が既にあることが前提です",
	Pro_Alert_Msg3: "タイトルを入力してください",
	Pro_Alert_Msg4: "入院記録のみ使用可能",
	Pro_Alert_Msg5: "保存後のみ使用可能",
	Pro_Alert_Msg6: "メッセージ送信後、入院記録を最適化できます",
	Pro_Alert_Msg7: "利用可能なサービスの種類を選択してください",
	Pro_Alert_Msg8: "返信されてからメッセージを保存することができます",
	Pro_Alert_Msg9: "メッセージの送信後にのみ再生成できます",
	Pro_Alert_Msg10: "このフィールドを空にすることはできません",
	Pro_SearchCase_Title: "症例を検索する",
	Pro_SearchCase_Content1: "サービスの種類",
	Pro_Date_String: "日付",
	Pro_Search_Btn: "検索",
	Pro_Clear_Btn: "クリア",
	Pro_Signup_Btn1: "活性化レターを再送信する",
	Pro_Setting_Pwd_Menu1: "元のパスワード",
	Pro_Setting_Pwd_Menu2: "新しいパスワード",
	Pro_Setting_Pwd_Menu3: "新しいパスワードをもう一度入力してください",
	Pro_Setting_Pwd_Msg1: "元のパスワードが間違って入力されました。確認してもう一度お試しください。",
	Pro_Setting_Pwd_Msg2: "パスワードは変更されました！",
	Pro_SignIn_Msg8: "アカウントにログインできません。カスタマーサービスにお問い合わせください。",
	Pro_Alert_Msg11: "このページを離れますか?",
	Pro_Alert_Msg12: "アイドルタイムアウト状態になったため、もう一度ログインしてください。",
	Pro_MS_Type_Menu1: "全て",
	Pro_MS_Type_Menu2: "録音",
	Pro_MS_Type_Menu3: "アップロード",
	Pro_Type_String: "タイプ",
	Pro_SignIn_Btn1: "Appleでログイン",
	Pro_SignIn_Btn2: "Googleでログイン",
	Pro_SignIn_Msg9: "アカウントはまだ有効化されていません。メールの受信箱を確認して認証を完了するか、活性化レターを再送して再試行してください。",
	Pro_MS_Upload_TTL: "音声ファイルのアップロード",
	Pro_MS_Upload_DSP: "ファイル サイズは最大 {fileSize} MB で、時間は {fileDuration} 分に制限されます。すべての一般的な音声ファイル形式をサポートします。",
	Pro_MS_Upload_Msg3: "ファイルが大きすぎます。最大許容サイズは {fileSize} MB です。",
	Pro_MS_Upload_Msg4: "ファイルの時間が {fileDuration} 分の制限を超えています。",
	Pro_MS_Upload_Msg5: "リクエストがタイムアウトしました。再度送信してください。",
	Pro_MS_Upload_Msg6: "ファイルをアップロード中…",
	Pro_MS_Upload_Msg7: "通訳中です。お待​​ちください…",
	Pro_MS_Upload_Btn1: "アップロード",
	Pro_Setting_Menu11: "音声言語",
	Pro_Forgot_Pwd_Msg1: "このアカウントは存在しません!",
	Pro_Forgot_Pwd_Msg2: "認証コードは正しいです!",
	Pro_Forgot_Pwd_Msg3: "入力した認証コードが間違っています。もう一度お試しください。"
}

/**i18多語字串：Vietnamese */
export const i18nString_vi: I18nString = {
	"Welcome to Dr.AI": "Chào mừng đến với Dr.AI",
	"Pro_Home_Title2": "Hỗ trợ không biên giới các chuyên gia y tế ",
	"Get Started": "Bắt đầu sử dụng",
	"Dr.AI": "Dr.AI",
	"Today": "Hôm nay",
	"+ New Case": "+ Ca bệnh mới",
	"-- Case is empty --": "-- Hiện không có ca bệnh --",
	"credits": "Điểm",
	"Valid until": "Có hiệu lực đến",
	"Logout": "Đăng xuất",
	"Admission Note": "Phiếu khám bệnh vào viện",
	"Progress Note": "Phiếu chăm sóc",
	"Pharmacotherapy": "Dược lý trị liệu",
	"Cancer Strategy": "Chiến lược điều trị ung thư",
	"Any Questions": "Câu hỏi bất kỳ",
	"Excellent": "Xuất sắc",
	"Good": "Tốt",
	"Fair": "Trung bình",
	"Poor": "Kém",
	"Generating. Results will be ready in 1-3 minutes.": "Đang tạo. Vui lòng chờ 1-3 phút.",
	"Regenerate": "Tạo lại",
	"Refine Admission Note & Generate Admission Orders": "Tạo Phiếu khám bệnh vào viện và y lệnh",
	"Edit": "Chỉnh sửa",
	"Save": "Lưu",
	"Please enter a Note Title using a keyword or the patient's chart number for easy search.": "Vui lòng nhập tiêu đề, có thể sử dụng từ khóa hoặc số hồ sơ để dễ dàng tìm kiếm",
	"Admission Date": "Ngày nhập viện",
	"Note Title": "Tiêu đề ghi chú",
	"Your case note": "Ghi chú ca bệnh của bạn",
	"Title": "Tiêu đề",
	"Copy": "Sao chép",
	"Download": "Tải xuống",
	"Save As": "Lưu thành",
	"Rename Title": "Đổi tên tiêu đề",
	"Edit Message": "Chỉnh sửa tin nhắn",
	"Your account balance is now depleted.": "Số dư tài khoản của bạn đã hết",
	"My Package": "Gói của tôi",
	"Cancel": "Hủy",
	"Please click on [Our plan] to access recharge functions.": "Vui lòng nhấp vào [Kế hoạch của chúng tôi] để truy cập vào chức năng nạp tiền.",
	"Are you sure you want to delete this information": "Bạn có chắc chắn muốn xóa thông tin này không?",
	"Yes": "Có",
	"Disclaimer for Dr.AI": "Tuyên bố khước từ trách nhiệm của Dr.AI",
	"I agree": "Tôi đồng ý",
	"Next": "Tiếp theo",
	"Introducing Dr.AI": "Giới thiệu về Dr.AI",
	"Embracing the Future of Healthcare, Combing the Best of AI Technologies": "Đón nhận tương lai chăm sóc sức khỏe, kết hợp công nghệ AI tiên tiến",
	"Empowering Healthcare Professionals:": "Hỗ trợ chuyên gia y tế:",
	"Unparalleled Data Analysis": "Khả năng phân tích dữ liệu tuyệt vời",
	"Assured Quality": "Đảm bảo chất lượng",
	"Proactive Health Predictions": "Dự đoán sức khỏe chủ động",
	"Personalized Treatments": "Điều trị cá nhân hóa",
	"Collaborative Healthcare": "Hợp tác chăm sóc sức khỏe",
	"Evidence-Informed Recommendations": "Khuyến nghị dựa trên bằng chứng",
	"Go Back": "Quay lại",
	"Please enter your email and follow the provided instructions to complete your password change.": "Vui lòng nhập email của bạn và làm theo hướng dẫn để hoàn thành thay đổi mật khẩu",
	"Forgot password": "Quên mật khẩu",
	"We have already sent a verification email to": "Chúng tôi đã gửi email xác minh đến",
	"Email": "Email",
	"Submit": "Gửi",
	"Auth_Error_Msg1": "Mã xác minh không chính xác.",
	"Enter verification code": "Nhập mã xác minh",
	"Resending…": "Gửi lại…",
	"Resend Code": "Gửi lại mã xác minh",
	"Log In": "Đăng nhập",
	"Email Address": "Địa chỉ email",
	"Sign up": "Đăng ký",
	"Hello": "Xin chào",
	"Password": "Mật khẩu",
	"Forgot password?": "Quên mật khẩu?",
	"Choose your plan": "Chọn gói của bạn",
	"Select the best plan for your needs": "Chọn gói phù hợp nhất với nhu cầu của bạn",
	"FREE Trial": "Dùng thử",
	"USD for 3 months": "USD trong 3 tháng",
	"Sign up and instantly receive 300 credits!": "Đăng ký và nhận ngay 300 điểm!",
	"300 credits for use": "300 điểm để sử dụng",
	"3-month trial period": "Thời gian dùng thử 3 tháng",
	"6 months of data preservation": "Lưu trữ dữ liệu 6 tháng",
	"Apply for Free Trial": "Đăng ký dùng thử",
	"Basic": "Gói cơ bản",
	"USD for 1 year": "USD trong 1 năm",
	"Begin experimenting with 300 credits over the course of a year.": "Bắt đầu trải nghiệm với 300 điểm trong một năm.",
	"1-year validity": "Thời hạn 1 năm",
	"18 months of data preservation": "Lưu trữ dữ liệu 18 tháng",
	"Includes data recovery (available upon credit refill)": "Bao gồm khôi phục dữ liệu (khôi phục đồng bộ khi nạp điểm)",
	"Coming Soon": "Sắp ra mắt",
	"Plus": "Gói nâng cao",
	"For more flexibility, start experimenting with 600 credits over a one-year span.": "Linh hoạt hơn với 600 điểm sử dụng trong một năm",
	"600 credits for use": "600 điểm để sử dụng",
	"Premium": "Gói cao cấp",
	"USD per month": "USD mỗi tháng",
	"Unlimited access tailored for healthcare professionals.": "Truy cập không giới hạn, được thiết kế dành riêng cho chuyên gia y tế",
	"Unlimited credits": "Không giới hạn số điểm",
	"Pro_Pack_Item6": "Giới hạn: 40 truy vấn liên tiếp sẽ tạm dừng phân tích trong 3 giờ",
	"Biomedical Technology, Inc.": "Biomedical Technology, Inc.",
	"not found": "Không tìm thấy",
	"Create a New Account": "Tạo tài khoản mới",
	"Male": "Nam",
	"Female": "Nữ",
	"Set up a password": "Cài đặt mật khẩu",
	"Confirm Password": "Xác nhận mật khẩu",
	"Your Detail": "Thông tin của bạn",
	"Pro_Home_Content1": "Dr.AI tích hợp liền mạch dịch vụ chăm sóc bệnh nhân nội trú và ngoại trú để giải quyết những thách thức của các chuyên gia y tế. Chăm sóc bệnh nhân nội trú tích hợp phiếu khám bệnh vào viện, y lệnh và phiếu chăm sóc, kết hợp với thuốc và chiến lược điều trị ung thư.\n\nChăm sóc bệnh nhân ngoại trú có ghi chú SOAP, ghi chú điều dưỡng và tóm tắt cuộc họp, giúp việc tương tác giữa bác sĩ và bệnh nhân trở nên đơn giản, tự động tạo kế hoạch điều trị, củng cố lộ trình lâm sàng và hỗ trợ chẩn đoán.", // 中間有換行, 記得加 '\n\n'
	"To thoroughly evaluate the patient's clinical conditions, Dr. AI needs specific information. This includes the patient's chief complaint, details of the current illness, a review of systems, the patient's past medical history, findings from the physical examination, and laboratory results. It is important to exclude any personally identifiable information, such as the patient's name or ID, from this data.": "Để đánh giá toàn diện tình trạng lâm sàng của bệnh nhân, Dr. AI cần thông tin cụ thể: lý do khám bệnh của bệnh nhân, bệnh hiện tại, đánh giá các bộ phận, tiền sử bệnh, kết quả khám lâm sàng, và kết quả xét nghiệm. Điều quan trọng là khi nhập dữ liệu phải loại trừ tất cả thông tin nhận dạng cá nhân, như họ tên hoặc số định danh của bệnh nhân.",
	"Select the appropriate Admission Note for evaluation from the left column, and provide details regarding the Subjective (S) and Objective (O) aspects of the patient's condition. This should include the patient's symptoms, observable signs, and laboratory results. Please avoid including any personally identifiable information, such as the patient's name or ID. If the sidebar does not offer any selection options, it indicates that you need to first create the patient's Admission Note before moving on to the Progress Note analysis with Dr. AI.": "Vui lòng chọn Phiếu khám bệnh vào viện cần phân tích ở cột bên trái, đồng thời cung cấp các thông tin Chủ quan (S) và Khách quan (O) liên quan đến tình trạng bệnh nhân, bao gồm triệu chứng, dấu hiệu có thể quan sát được và kết quả xét nghiệm của bệnh nhân để tiến hành đánh giá. Vui lòng không nhập bất kỳ thông tin nhận dạng cá nhân nào như họ tên hoặc số ID của bệnh nhân. Nếu cột bên trái không cung cấp bất kỳ tùy chọn nào, bạn cần tạo Phiếu khám bệnh của bệnh nhân trước khi sử dụng Dr.AI để phân tích Phiếu chăm sóc.",
	"On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.": "Trên nền tảng Dr. AI, chuyên gia y tế có thể sử dụng một cơ sở dữ liệu dược phẩm tiên tiến và toàn diện. Hệ thống tiên tiến này có khả năng truy xuất nhanh chóng và chính xác thông tin đa dạng bao gồm tác dụng phụ, các thông số giám sát, biến cố không mong muốn và các lưu ý về thuốc. Bạn cũng có thể chọn Phiếu khám bệnh vào viện phù hợp từ thanh bên bên trái để đánh giá và nhập các câu hỏi liên quan đến điều trị bằng thuốc của bệnh nhân. Vui lòng không nhập bất kỳ thông tin nhận dạng cá nhân nào như họ tên hoặc số định danh của bệnh nhân. Nếu không có tùy chọn nào có sẵn ở thanh bên, bạn cần tạo Phiếu khám bệnh vào viện trước khi tiến hành phân tích điều trị bằng thuốc trên Dr.AI.",
	"Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.": "Xây dựng kế hoạch điều trị ung thư đòi hỏi thông tin toàn diện của bệnh nhân. Các dữ liệu cần thiết cung cấp bao gồm bệnh sử chi tiết, loại và giai đoạn ung thư, báo cáo giải phẫu bệnh, kết quả xét nghiệm gen, tình trạng sức khỏe, báo cáo hình ảnh, lịch sử điều trị và sở thích của bệnh nhân. Bạn cũng có thể chọn Phiếu khám bệnh vào viện thích hợp từ thanh bên để đánh giá. Điều quan trọng là đảm bảo không bao gồm bất kỳ thông tin nhận dạng cá nhân nào như họ tên hoặc số định danh của bệnh nhân. Nếu thanh bên không hiển thị bất kỳ tùy chọn nào, bạn cần là tạo Phiếu khám bệnh vào viện của bệnh nhân trước khi sử dụng Dr.AI xây dựng chiến lược điều trị ung thư.",
	"Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.": "Dr.AI được thiết kế để theo dõi tình trạng của bệnh nhân ở các giai đoạn khác nhau trong thời gian nằm viện (lúc nhập viện, trong khi nằm viện, lúc xuất viện và sau khi xuất viện). Bạn có thể đặt bất kỳ câu hỏi nào liên quan đến bệnh nhân. Bạn có thể chọn Phiếu khám bệnh vào viện từ thanh bên trái để phân tích. Cần loại trừ tất cả thông tin nhận dạng cá nhân như họ tên hoặc số định danh của bệnh nhân. Nếu thanh bên trái không hiển thị lựa chọn nào, bạn nên tạo Phiếu khám bệnh vào viện trước khi tiếp tục hỏi đáp trên Dr.AI.",
	"Don't have an account?": "Bạn chưa có tài khoản?",
	"Search...": "Tìm kiếm...",
	"Please check your email for the 6-digit verification code and enter it in the fields below": "Vui lòng kiểm tra email của bạn để nhận mã xác minh gồm 6 chữ số và nhập vào các trường bên dưới",
	"Chief complaint, Present illness, Review of systems, Past medical history, Physical exam, and Laboratory results": "Lý do khám bệnh, bệnh hiện tại, đánh giá các bộ phận, tiền sử bệnh, khám lâm sàng, và kết quả xét nghiệm",
	"The Subjective and Objective elements include the patient's symptoms, clinical signs, and Laboratory data": "Các yếu tố chủ quan và khách quan bao gồm triệu chứng của bệnh nhân, dấu hiệu lâm sàng và dữ liệu xét nghiệm",
	"Questions regarding the patient's current medication and pertinent pharmacotherapy regimen.": "Câu hỏi liên quan đến thuốc hiện tại của bệnh nhân và phác đồ điều trị bằng thuốc phù hợp.",
	"Details on cancer type and stage, medical history, pathology reports, genetic testing results, performance status, radiological findings, treatment history, and patient preferences": "Thông tin về loại và giai đoạn ung thư, tiền sử bệnh, báo cáo giải phẫu bệnh, kết quả xét nghiệm gen, tình trạng sức khỏe, phát hiện hình ảnh, lịch sử điều trị và sở thích của bệnh nhân",
	"Raise any questions": "Đặt câu hỏi",
	"Your Email": "Email của bạn",
	"fill in Captcha": "Điền mã xác minh",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, and numbers.": "Mật khẩu cần có ít nhất 8 ký tự, bao gồm chữ cái in hoa, chữ thường và số.",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.": "Mật khẩu cần có ít nhất 8 ký tự, bao gồm chữ cái in hoa, chữ thường, số và ký tự đặc biệt.",
	"Enter password": "Nhập mật khẩu",
	"Inquiries concerning the patient's ongoing medication or relevant pharmacotherapy regimen.": "Câu hỏi về thuốc đang dùng hoặc phác đồ điều trị bằng thuốc có liên quan của bệnh nhân.",
	"Please enter your email and follow the instructions to finish changing your password.": "Vui lòng nhập email của bạn và làm theo hướng dẫn để hoàn thành thay đổi mật khẩu.",
	"Pro_Home_Link1": "Tìm hiểu thêm",
	"Pro_Jump_Title1": "Điều cần chú ý khi đăng ký Dr.AI trên điện thoại",
	"Pro_Jump_Title2": "Phiên bản di động của Dr.AI hiện đang được xây dựng.",
	"Pro_Jump_Content1": "Vui lòng xoay điện thoại sang chế độ ngang để đăng ký.\nSau khi đăng ký trên điện thoại, vui lòng quay lại máy tính để trải nghiệm đầy đủ Dr.AI. Xin lỗi vì sự bất tiện này!",
	"Pro_Jump_Content2": "P.S. Xin mời xem trước video hướng dẫn thao tác trên giao diện của Dr.AI.",
	Pro_Main_Menu1: "Bệnh nhân nội trú",
	Pro_Main_Menu2: "Bệnh nhân ngoại trú",
	Pro_Main_Menu3: "Thư ký y khoa",
	Pro_SOAP_TTL: "Ghi chú SOAP",
	Pro_SOAP_DSP: "Ghi chú SOAP là phương pháp ghi chép các thông tin chủ quan, khách quan, đánh giá và kế hoạch điều trị được sử dụng rộng rãi bởi các nhà cung cấp dịch vụ chăm sóc sức khỏe . Trong môi trường ngoại trú, để đánh giá kỹ lưỡng tình trạng lâm sàng của bệnh nhân, Dr.AI yêu cầu thông tin cụ thể, bao gồm các triệu chứng của bệnh nhân, bệnh hiện tại, tiền sử bệnh, kết quả khám lâm sàng và kết quả xét nghiệm. Bạn cũng có thể chọn một bản ghi từ danh sách các bản ghi hội thoại của thư ký y khoa để tạo ghi chú SOAP. Hoặc bạn có thể tiếp tục cuộc hội thoại để theo dõi một ghi chú SOAP đã được tạo trước đó. Điều quan trọng là phải loại trừ bất kỳ thông tin cá nhân nào có thể nhận dạng được khỏi dữ liệu này, chẳng hạn như tên hoặc ID của bệnh nhân.",
	Pro_SOAP_TIP: "Nhập các triệu chứng, dấu hiệu lâm sàng, dữ liệu xét nghiệm và bản ghi hội thoại y tế trực tiếp, hoặc tiếp tục cuộc hội thoại theo ghi chú.",
	Pro_MS_List_TTL: "Danh sách bản ghi âm của Thư ký y khoa:",
	Pro_MS_List_DSP: "Vui lòng chọn một mục từ danh sách các bản ghi âm để phân tích thêm.",
	Pro_MS_List_Msg1: "Không có dữ liệu",
	Pro_MS_Recording_TTL: "Ghi chú của Thư ký y khoa",
	Pro_MS_Recording_Msg1: "Lưu và thoát màn hình?",
	Pro_Setting_Btn1: "Xác nhận",
	Pro_Setting_Menu1: "Kế hoạch hiện tại",
	Pro_Setting_Menu2: "Ngôn ngữ hệ thống",
	Pro_Setting_Menu3: "Họ tên",
	Pro_Setting_Menu4: "Ngôn ngữ phản hồi",
	Pro_Setting_Version: "Phiên bản",
	Pro_Tab_Topic_Title: "Chọn dịch vụ",
	Pro_Tab_Menu1: "Danh sách Thư ký y khoa",
	Pro_Tab_Menu2: "Lịch sử ca bệnh",
	Pro_Tab_Menu3: "Cài đặt",
	Settings: "Cài đặt",
	Pro_NursingN_DSP: "Chức năng Ghi chú điều dưỡng ghi lại các hoạt động chăm sóc, quan sát điều dưỡng và can thiệp y tế do điều dưỡng viên thực hiện. Hệ thống hỗ trợ định dạng bản ghi y tế tiêu chuẩn toàn cầu, có thể tự động tạo ra các định dạng SOAPIE, ISBAR và DAR theo yêu cầu. Bản ghi chú điều dưỡng đầy đủ có thể hỗ trợ việc bàn giao và phối hợp giữa các điều dưỡng viên trong bệnh viện, cũng như chăm sóc và giao tiếp với bệnh nhân. Lưu ý loại bỏ bất kỳ thông tin cá nhân nào có thể nhận dạng.",
	Pro_NursingN_TIP: "Vui lòng nhập thông tin chủ quan và khách quan, bao gồm quan sát điều dưỡng, hoạt động chăm sóc và can thiệp y tế.",
	Pro_ConsultD_DSP: "Chức năng tư vấn Dr.AI đưa ra những hướng dẫn và khuyến nghị chuyên nghiệp để hỗ trợ ra quyết định lâm sàng trong các cuộc hội chẩn ngoại trú, nâng cao chất lượng và hiệu quả của việc chăm sóc bệnh nhân. Bạn cũng có thể tham gia thảo luận liên tục về tình trạng lâm sàng của bệnh nhân để hiểu thêm về các khả năng lâm sàng. Lưu ý loại bỏ bất kỳ thông tin cá nhân nào có thể nhận dạng.",
	Pro_ConsultD_TIP: "Vui lòng nhập một loạt câu hỏi để phân tích toàn diện.",
	Pro_MeetingR_DSP: "Tính năng Tóm tắt cuộc họp tự động tạo ra các bản tóm tắt cuộc thảo luận, quyết định và hành động trong cuộc họp của đội ngũ y tế theo định dạng y tế tiêu chuẩn. Điều này đảm bảo tính nhất quán trong kế hoạch và mục tiêu chăm sóc bệnh nhân của đội ngũ y tế. Đối với cuộc họp thảo luận ca bệnh, nên ghi lại mỗi ca bệnh trong một tệp âm thanh riêng biệt.",
	Pro_MeetingR_TIP: "Vui lòng chọn tệp ghi âm cuộc họp để phân tích.",
	Pro_MS_Upload_Msg1: "Vui lòng chọn định dạng tệp được hỗ trợ.",
	Pro_MS_Upload_Msg2: "Kích thước tệp tối đa là {uploadFileMaxSize} MB. Các định dạng tệp đang được hỗ trợ: {uploadFileFormats}",
	Pro_MS_Upload_Msg2_Size: "Kích thước tệp tối đa là {uploadFileMaxSize} MB.",
	Pro_System_Msg1: "Đang tải…",
	Pro_System_Msg2: "Đang xử lý…",
	Pro_System_Msg3: "Hệ thống đang bận, vui lòng thử lại sau.",
	Pro_NursingN_TTL: "Ghi chú điều dưỡng",
	Pro_ConsultD_TTL: "Tư vấn Dr.AI",
	Pro_MeetingR_TTL: "Tóm tắt cuộc họp",
	Pro_Setting_Btn2: "Chỉnh sửa thông tin cá nhân",
	Pro_Setting_Btn3: "Kế hoạch khác",
	Pro_Setting_Btn4: "Chi tiết tài khoản",
	Pro_Setting_Btn5: "Chỉnh sửa",
	Pro_Setting_Menu5: "Biệt danh",
	Pro_Setting_Menu6: "Kế hoạch",
	Pro_Setting_Menu7: "Số dư điểm tài khoản",
	Pro_Setting_Menu8: "Ngày hết hạn",
	Pro_Setting_Menu9: "Địa chỉ email",
	Pro_System_Msg4: "Đã sao chép!",
	Pro_Signup_Msg1: "Cảm ơn bạn đã đăng ký! Vui lòng kiểm tra hộp thư điện tử của bạn để xác minh tài khoản. Đồng thời, hãy nhớ kiểm tra thư mục Thư rác để đảm bảo bạn nhận được email của chúng tôi.",
	Pro_Setting_Menu10: "Ngôn ngữ",
	Pro_Setting_Msg1: "Vui lòng chọn tệp hình ảnh hợp lệ (jpg hoặc png).",
	Pro_LogOut_Msg1: "Bạn có chắc chắn muốn đăng xuất?",
	Pro_SignIn_Content1: "Hoặc đăng nhập theo cách này",
	Pro_SignIn_Msg1: `Nhắc nhở! Nếu đây là lần đầu tiên bạn đăng nhập vào trang web này thông qua tính năng "Đăng nhập bằng tài khoản Apple", hãy đảm bảo tùy chọn email của bạn là “Chia sẻ địa chỉ Email”.`,
	Pro_SignIn_Msg2: `Hệ thống phát hiện bạn đã chọn “Ẩn địa chỉ Email”. Vui lòng đặt lại cài đặt của bạn và thử lại.`,
	Pro_SignIn_Msg3: "Hệ thống phát hiện trình duyệt của bạn đang chặn các cửa sổ bật lên. Vui lòng kiểm tra cài đặt trình duyệt của bạn và thử lại.",
	Pro_SignIn_Msg4: "Đã gửi",
	Pro_SignIn_Msg5: "Email hoặc mật khẩu của bạn không chính xác. Vui lòng thử lại.",
	Pro_SignIn_Msg6: "Vui lòng nhập địa chỉ email của bạn.",
	Pro_SignIn_Msg7: "Email đã được đăng ký!",
	Pro_Register_Msg1: "Mật khẩu không chính xác!",
	Pro_Register_Msg2: "Đăng ký không thành công, vui lòng liên hệ bộ phận chăm sóc khách hàng!",
	Pro_Alert_Msg1: "Chức năng Phiếu chăm sóc bị khóa.",
	Pro_Alert_Msg2: "Lưu Phiếu chăm sóc yêu cầu cần có Giấy khám bệnh vào viện liên quan.",
	Pro_Alert_Msg3: "Vui lòng điền tiêu đề",
	Pro_Alert_Msg4: "Chỉ có trong Phiếu khám bệnh vào viện?",
	Pro_Alert_Msg5: "Chỉ khả dụng sau khi lưu",
	Pro_Alert_Msg6: "Phiếu khám bệnh vào viện chỉ có thể được tinh chỉnh sau khi gửi tin nhắn",
	Pro_Alert_Msg7: "Vui lòng chọn loại dịch vụ có sẵn",
	Pro_Alert_Msg8: "Sau khi nhận phản hồi tin nhắn mới có thể lưu.",
	Pro_Alert_Msg9: "Sau khi gửi tin nhắn mới có thể tạo lại",
	Pro_Alert_Msg10: "Trường này không thể để trống",
	Pro_SearchCase_Title: "Tìm kiếm ca bệnh",
	Pro_SearchCase_Content1: "Loại dịch vụ",
	Pro_Date_String: "Ngày tháng",
	Pro_Search_Btn: "Tìm kiếm",
	Pro_Clear_Btn: "Xóa",
	Pro_Signup_Btn1: "Gửi lại Email kích hoạt",
	Pro_Setting_Pwd_Menu1: "Mật khẩu cũ",
	Pro_Setting_Pwd_Menu2: "Mật khẩu mới",
	Pro_Setting_Pwd_Menu3: "Nhập lại mật khẩu mới",
	Pro_Setting_Pwd_Msg1: "Mật khẩu ban đầu được nhập không chính xác. Vui lòng xác nhận và thử lại!",
	Pro_Setting_Pwd_Msg2: "Đã đổi mật khẩu thành công!",
	Pro_SignIn_Msg8: "Tài khoản của bạn không thể đăng nhập. Vui lòng liên hệ bộ phận chăm sóc khách hàng!",
	Pro_Alert_Msg11: "Bạn có chắc chắn muốn rời khỏi trang này?",
	Pro_Alert_Msg12: "Tài khoản của bạn đã không hoạt động trong thời gian dài. Vui lòng đăng nhập lại!",
	Pro_MS_Type_Menu1: "Tất cả",
	Pro_MS_Type_Menu2: "Ghi âm",
	Pro_MS_Type_Menu3: "Tải lên",
	Pro_Type_String: "Phân loại",
	Pro_SignIn_Btn1: "Đăng nhập bằng tài khoản Apple của bạn",
	Pro_SignIn_Btn2: "Đăng nhập bằng tài khoản Google của bạn",
	Pro_SignIn_Msg9: "Tài khoản của bạn chưa được kích hoạt. Vui lòng kiểm tra email và hoàn tất xác minh hoặc gửi lại email kích hoạt và thử lại.",
	Pro_MS_Upload_TTL: "Tải lên tập tin âm thanh",
	Pro_MS_Upload_DSP: "Kích thước tối đa của tệp là {fileSize} MB và thời lượng tối đa của tệp là {fileDuration} phút. Hệ thống hỗ trợ tất cả các định dạng tập tin âm thanh phổ biến.",
	Pro_MS_Upload_Msg3: "Tệp của bạn quá lớn. Kích thước tối đa được phép là {fileSize} MB.",
	Pro_MS_Upload_Msg4: "Độ dài tệp vượt quá giới hạn {fileDuration} phút.",
	Pro_MS_Upload_Msg5: "Yêu cầu đã hết thời gian, vui lòng gửi lại.",
	Pro_MS_Upload_Msg6: "Đang tải tệp lên…",
	Pro_MS_Upload_Msg7: "Đang dịch, vui lòng đợi…",
	Pro_MS_Upload_Btn1: "Tải lên",
	Pro_Setting_Menu11: "Ngôn ngữ nói",
	Pro_Forgot_Pwd_Msg1: "Tài khoản này không tồn tại!",
	Pro_Forgot_Pwd_Msg2: "Mã xác minh chính xác!",
	Pro_Forgot_Pwd_Msg3: "Mã xác minh bạn nhập không chính xác, vui lòng thử lại!"
}

/**i18多語字串：简体中文 */
export const i18nString_sc: I18nString = {
	"Welcome to Dr.AI": "欢迎造访Dr.AI",
	"Pro_Home_Title2": "无疆界支持医疗专业人员",
	"Get Started": "开始使用",
	"Dr.AI": "Dr.AI",
	"Today": "今天",
	"+ New Case": "+ 新病例",
	"-- Case is empty --": "-- 目前无病例 --",
	"credits": "点数",
	"Valid until": "有效期至",
	"Logout": "登出",
	"Admission Note": "入院记录",
	"Progress Note": "病程记录",
	"Pharmacotherapy": "药物治疗",
	"Cancer Strategy": "癌症治疗策略",
	"Any Questions": "任何问题",
	"Excellent": "优",
	"Good": "好",
	"Fair": "普通",
	"Poor": "差",
	"Generating. Results will be ready in 1-3 minutes.": "分析中…请稍候1-3分钟",
	"Regenerate": "重新生成",
	"Refine Admission Note & Generate Admission Orders": "优化入院记录并生成入院医嘱",
	"Edit": "编辑",
	"Save": "保存",
	"Please enter a Note Title using a keyword or the patient's chart number for easy search.": "请输入标题，可使用关键字或病例号以便于搜寻",
	"Admission Date": "入院日期",
	"Note Title": "标题",
	"Your case note": "您的病例记录",
	"Title": "标题",
	"Copy": "複製",
	"Download": "下载",
	"Save As": "另存为",
	"Rename Title": "重新命名标题",
	"Edit Message": "编辑讯息",
	"Your account balance is now depleted.": "您的帐户馀额已用完",
	"My Package": "我的方案",
	"Cancel": "取消",
	"Please click on [Our plan] to access recharge functions.": "请点击[我们的方案]以切换到储值功能",
	"Are you sure you want to delete this information": "您确定要删除此讯息吗?",
	"Yes": "是",
	"Disclaimer for Dr.AI": "Dr.AI 免责声明",
	"I agree": "我同意",
	"Next": "下一步",
	"Introducing Dr.AI": "关于Dr.AI",
	"Embracing the Future of Healthcare, Combing the Best of AI Technologies": "拥抱明日医疗 结合最先进的人工智慧技术",
	"Empowering Healthcare Professionals:": "赋能医疗照护专家：",
	"Unparalleled Data Analysis": "无与伦比的数据分析能力",
	"Assured Quality": "质量保证",
	"Proactive Health Predictions": "主动的健康预测",
	"Personalized Treatments": "个性化治疗",
	"Collaborative Healthcare": "协同医疗照护",
	"Evidence-Informed Recommendations": "基于实证的建议",
	"Go Back": "返回",
	"Please enter your email and follow the provided instructions to complete your password change.": "请输入您的电子邮件并按照说明完成更改密码",
	"Forgot password": "忘记密码",
	"We have already sent a verification email to": "我们已经发送了一封验证邮件到",
	"Email": "电子邮件",
	"Submit": "提交",
	"Auth_Error_Msg1": "输入的验证码不正确",
	"Enter verification code": "输入验证码",
	"Resending…": "重新发送",
	"Resend Code": "重新发送验证码",
	"Log In": "登录",
	"Email Address": "电子邮件地址",
	"Sign up": "注册",
	"Hello": "您好",
	"Password": "密码",
	"Forgot password?": "忘记密码？",
	"Choose your plan": "选择您的方案",
	"Select the best plan for your needs": "选择最适合您需求的方案",
	"FREE Trial": "免费试用",
	"USD for 3 months": " USD 为期3个月",
	"Sign up and instantly receive 300 credits!": "立即注册并获得300个点数！",
	"300 credits for use": "可使用300个点数",
	"3-month trial period": "3个月试用期",
	"6 months of data preservation": "6个月的数据保存",
	"Apply for Free Trial": "申请免费试用",
	"Basic": "基础版",
	"USD for 1 year": "USD 为期1年",
	"Begin experimenting with 300 credits over the course of a year.": "一年内可使用300个点数体验",
	"1-year validity": "1年有效期",
	"18 months of data preservation": "18个月的数据保存",
	"Includes data recovery (available upon credit refill)": "包括数据恢復（于点数充值时同步恢復）",
	"Coming Soon": "即将推出",
	"Plus": "进阶版",
	"For more flexibility, start experimenting with 600 credits over a one-year span.": "更大的灵活性，一年内可使用600个点数体验",
	"600 credits for use": "可使用600个点数",
	"Premium": "顶级版",
	"USD per month": "USD 月定制",
	"Unlimited access tailored for healthcare professionals.": "为医疗专家量身打造的海量分析",
	"Unlimited credits": "无限制点数",
	"Pro_Pack_Item6": "限制：40个连续使用查询将暂停分析3小时",
	"Biomedical Technology, Inc.": "Biomedical Technology, Inc.",
	"not found": "未找到",
	"Create a New Account": "创建新帐户",
	"Male": "男性",
	"Female": "女性",
	"Set up a password": "设置密码",
	"Confirm Password": "确认密码",
	"Your Detail": "您的详情",
	"Pro_Home_Content1": "Dr.AI 无缝整合住院及门诊照护，解决医疗专业人员的挑战。在住院照护中，其整合了入院记录、医嘱和病程记录，并结合药物治疗和癌症治疗策略。\n\n在门诊照护上，搭载了SOAP记录、护理记录、会议摘要，简化医病互动并自动生成治疗计划，强化临床途径，提供辅助诊断的支援。", // 中間有換行, 記得加 '\n\n'
	"To thoroughly evaluate the patient's clinical conditions, Dr. AI needs specific information. This includes the patient's chief complaint, details of the current illness, a review of systems, the patient's past medical history, findings from the physical examination, and laboratory results. It is important to exclude any personally identifiable information, such as the patient's name or ID, from this data.": "为了全面评估病人的临床状况，Dr. AI 需要具体的讯息，这包括病人的主诉、目前疾病的详细讯息、系统回顾、病人的过往病史、身体检查的发现，以及实验室结果。重要的是输入资料一定要排除任何个人身份资讯，如患者的姓名或身份证号码",
	"Select the appropriate Admission Note for evaluation from the left column, and provide details regarding the Subjective (S) and Objective (O) aspects of the patient's condition. This should include the patient's symptoms, observable signs, and laboratory results. Please avoid including any personally identifiable information, such as the patient's name or ID. If the sidebar does not offer any selection options, it indicates that you need to first create the patient's Admission Note before moving on to the Progress Note analysis with Dr. AI.": "从左栏选择适当的入院记录以进行评估，并提供有关病人的主观（S）和客观（O）方面的详细病况。这应包括病人的症状、可观察到的徵象和实验室结果。请避免输入任何个人身份识别资料，如病人的名字或身份证号。如果侧栏没有提供任何选择项目，这表示您需要先创建病人的入院记录，然后再进行 Dr. AI 的病程分析",
	"On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.": "在 Dr. AI 平台上，医疗照护专家可以使用一个先进而全面的药物资料库。这个先进的系统能够快速且准确地检索包括副作用、监测参数、不良事件和药品注意事项在内的各种讯息。此外，您可以从左侧栏中选择相关的入院记录进行评估，并输入与病人的药物治疗相关的问题。请勿输入任何个人身份识别资料，如病人的名字或身份证号。如果侧栏中没有可选择的选项，这表示您需要先创建病人的入院记录，然后再进行 Dr. AI 的药物治疗分析",
	"Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.": "产生癌症治疗计划需要全面的病患状况。应提供必要的数据，如仔细的医疗史、癌症的类型和分期、病理报告、基因检测结果、病情状态、放射学报告、先前的治疗史，以及病人的偏好。此外，您可以从侧栏中选择适当的入院记录进行合併分析。请确保不包括任何个人身份识别资讯，如病人的名字或身份证号，这一点极为重要。如果侧栏没有显示任何可选择的项目，这表示您的首要步骤是创建病人的入院记录，然后再与 Dr. AI 一起制定癌症治疗策略",
	"Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.": "Dr. AI 被用来监测病人在住院的不同阶段可能产生的健康问题，包括入院时、住院期间、出院时、以及出院后。欢迎随时提出任何问题，此外您可以从左侧栏中选择适当的入院记录进行分析。记得要排除个人识别资料，如病人的名字或身份证号。如果您在侧栏中看不到任何选项，这表示您应该先创建病人的入院记录，然后再进行与Dr. AI的问答交流",
	"Don't have an account?": "没有帐户吗？",
	"Search...": "搜寻...",
	"Please check your email for the 6-digit verification code and enter it in the fields below": "请至您的Email信箱收取6位数验证码，并填入下面栏位",
	"Chief complaint, Present illness, Review of systems, Past medical history, Physical exam, and Laboratory results": "主诉、现在病况、系统回顾、过去病史、身体检查、及实验室结果",
	"The Subjective and Objective elements include the patient's symptoms, clinical signs, and Laboratory data": "主观和客观要素，包括病人的症状、临床徵象和实验室数据",
	"Questions regarding the patient's current medication and pertinent pharmacotherapy regimen.": "有关病人目前用药和相关药物治疗计划的问题",
	"Details on cancer type and stage, medical history, pathology reports, genetic testing results, performance status, radiological findings, treatment history, and patient preferences": "关于癌症类型和分期、医疗病史、病理报告、遗传检测结果、病情状态、放射学发现、治疗史，以及病人偏好",
	"Raise any questions": "提出任何问题",
	"Your Email": "您的电子邮件",
	"fill in Captcha": "填写验证码",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, and numbers.": "密码应至少包含8个字符，并包含大写和小写字母以及数字。",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.": "密码应至少包含8个字符，并包含大写和小写字母、数字和特殊字符。",
	"Enter password": "输入密码",
	"Inquiries concerning the patient's ongoing medication or relevant pharmacotherapy regimen.": "有关患者正在进行的药物治疗或相关药物治疗方案的询问。",
	"Please enter your email and follow the instructions to finish changing your password.": "请输入您的电子邮件并按照指示完成密码变更",
	"Pro_Home_Link1": "了解更多",
	"Pro_Jump_Title1": "使用手机注册 Dr.AI 注意事项",
	"Pro_Jump_Title2": "Dr.AI 『手机版』目前尚在建置中",
	"Pro_Jump_Content1": "请将手机转成『横向操作』以便进行注册。\n手机注册完成后，再请回到桌机来体验Dr.AI。造成您的不便 敬请见谅!",
	"Pro_Jump_Content2": "P.S. 欢迎预览Dr.AI接口操作情境影片",
	Pro_Main_Menu1: "住院病患",
	Pro_Main_Menu2: "门诊病患",
	Pro_Main_Menu3: "医疗书写员",
	Pro_SOAP_TTL: "SOAP记录",
	Pro_SOAP_DSP: "主观、客观、评估和计划（SOAP）记录是医疗专业人员广泛使用的记录方法。在门诊环境中，为了彻底评估病患的临床状况，Dr.AI 需要特定的信息，包括病患的症状、现在病情、过去的病史、身体检查和实验室检查结果。您也可以从医疗书写员录音列表中选择对话记录来生成SOAP记录；或者您也可以用已创建的SOAP记录来继续对话。请您注意务必从数据中排除任何可识别个人身份的讯息，如病患的姓名或ID。",
	Pro_SOAP_TIP: "请输入症状、临床征象、实验室数据和直接的医疗对话记录，或根据原本的记录继续对话。",
	Pro_MS_List_TTL: "医疗书写员的语音列表：",
	Pro_MS_List_DSP: "请从语音列表中选择项目以进行进一步分析",
	Pro_MS_List_Msg1: "无资料",
	Pro_MS_Recording_TTL: "医疗书写员记录",
	Pro_MS_Recording_Msg1: "您要储存后离开吗？",
	Pro_Setting_Btn1: "确认",
	Pro_Setting_Menu1: "目前方案",
	Pro_Setting_Menu2: "系统语言",
	Pro_Setting_Menu3: "姓名",
	Pro_Setting_Menu4: "回复语言",
	Pro_Setting_Version: "版本",
	Pro_Tab_Topic_Title: "选择服务",
	Pro_Tab_Menu1: "医疗书写员列表",
	Pro_Tab_Menu2: "历史病例",
	Pro_Tab_Menu3: "设置",
	Settings: "设置",
	Pro_NursingN_DSP: "护理记录的功能是记录护理人员所执行的照护行动、护理观察与医疗介入。并搭载全球标准的医疗记录格式，可依据您的指令自动生成 SOAPIE、ISBAR 和 DAR 的格式，完整的护理记录可辅助院内护理人员交接的协调性，以及患者的照护与沟通。请您注意务必从数据中排除任何可识别个人身份的讯息。",
	Pro_NursingN_TIP: "请输入主观和客观数据，包括护理观察、照护行动和医疗介入",
	Pro_ConsultD_DSP: "咨询Dr.AI的功能可寻求Dr.AI的专业指导和建议，以支持门诊会诊中的临床决策，并提高患者照护的质量和成效。您也可以依据病患的临床情况提出连续性的问题来讨论，以了解更多临床的可能性。请您注意务必从数据中排除任何可识别个人身份的讯息。",
	Pro_ConsultD_TIP: "请输入一系列问题以进行全方位分析",
	Pro_MeetingR_DSP: "会议摘要功能是根据标准的医疗格式自动生成医疗团队会议中的讨论、决策和行动项目的摘要，确保医疗团队的病患照护计划和目标保持一致。若为病例讨论会议之语音分析，建议每位病例独立一个音文件记录。",
	Pro_MeetingR_TIP: "请选择会议语音录音档案以供分析",
	Pro_MS_Upload_Msg1: "请选择支援的档案格式",
	Pro_MS_Upload_Msg2: "档案容量上限为 {uploadFileMaxSize} MB，目前支援的档案格式：{uploadFileFormats}",
	Pro_MS_Upload_Msg2_Size: "档案容量上限为 {uploadFileMaxSize} MB",
	Pro_System_Msg1: "加载中…",
	Pro_System_Msg2: "处理中…",
	Pro_System_Msg3: "系统忙碌中，请稍后再试",
	Pro_NursingN_TTL: "护理记录",
	Pro_ConsultD_TTL: "咨询Dr.AI",
	Pro_MeetingR_TTL: "會議摘要",
	Pro_Setting_Btn2: "编辑个人资讯",
	Pro_Setting_Btn3: "更多方案",
	Pro_Setting_Btn4: "帐户明细",
	Pro_Setting_Btn5: "编辑",
	Pro_Setting_Menu5: "昵称",
	Pro_Setting_Menu6: "方案",
	Pro_Setting_Menu7: "帐户点数余额",
	Pro_Setting_Menu8: "到期日",
	Pro_Setting_Menu9: "电子邮件地址",
	Pro_System_Msg4: "已复制！",
	Pro_Signup_Msg1: "感谢您注册！请查看您的电子邮箱以进行账户验证。同时，请记得检查垃圾邮箱，以确保您能够收到我们的邮件。",
	Pro_Setting_Menu10: "语言",
	Pro_Setting_Msg1: "请选择有效的图片文件 (jpg 或 png)",
	Pro_LogOut_Msg1: "您确定要登出吗？",
	Pro_SignIn_Content1: "或以此方式登入",
	Pro_SignIn_Msg1: `提醒您！若为首次透过「使用 Apple 帐号登入」功能登入本网站，请务必将电子邮件选项设定为「分享邮件地址」。`,
	Pro_SignIn_Msg2: `系统已侦测到您选择了「隐藏邮件地址」选项，请重置设定后并再试一次。`,
	Pro_SignIn_Msg3: "系统已侦测到浏览器阻挡弹出视窗显示，请确认浏览器设定后并再试一次。",
	Pro_SignIn_Msg4: "已发送",
	Pro_SignIn_Msg5: "您的电子邮件或密码不正确。请重试。",
	Pro_SignIn_Msg6: "请输入您的电子邮件地址",
	Pro_SignIn_Msg7: "邮箱已注册！",
	Pro_Register_Msg1: "密码错误！",
	Pro_Register_Msg2: "注册失败，请联系客服人员！",
	Pro_Alert_Msg1: "病程记录功能锁定中",
	Pro_Alert_Msg2: "病程记录保存需要先存在相关之入院纪录",
	Pro_Alert_Msg3: "请填写标题",
	Pro_Alert_Msg4: "仅在入院记录中可用",
	Pro_Alert_Msg5: "仅在保存后可用",
	Pro_Alert_Msg6: "发送讯息后才能优化入院记录",
	Pro_Alert_Msg7: "请选择可用的服务类型",
	Pro_Alert_Msg8: "待讯息回覆后才能保存",
	Pro_Alert_Msg9: "发送讯息后才能重新生成",
	Pro_Alert_Msg10: "该栏位不能为空",
	Pro_SearchCase_Title: "搜寻病例",
	Pro_SearchCase_Content1: "服务类型",
	Pro_Date_String: "日期",
	Pro_Search_Btn: "搜索",
	Pro_Clear_Btn: "清除",
	Pro_Signup_Btn1: "重送启用信",
	Pro_Setting_Pwd_Menu1: "原密码",
	Pro_Setting_Pwd_Menu2: "新密码",
	Pro_Setting_Pwd_Menu3: "再次输入新密码",
	Pro_Setting_Pwd_Msg1: "原密码输入错误，请您确认后再试一次！",
	Pro_Setting_Pwd_Msg2: "密码变更成功！",
	Pro_SignIn_Msg8: "您的帐户已无法登入，请联系客服人员！",
	Pro_Alert_Msg11: "您确定要离开此页面吗？",
	Pro_Alert_Msg12: "您的帐号已闲置逾时，请您重新登入！",
	Pro_MS_Type_Menu1: "全部",
	Pro_MS_Type_Menu2: "录音",
	Pro_MS_Type_Menu3: "上传",
	Pro_Type_String: "类型",
	Pro_SignIn_Btn1: "使用Apple帐号登入",
	Pro_SignIn_Btn2: "使用Google帐号登入",
	Pro_SignIn_Msg9: "您的帐户尚未启用，请检查 Email 信箱并完成认证或重送启用信后再试一次。",
	Pro_MS_Upload_TTL: "音档上传",
	Pro_MS_Upload_DSP: "档案大小限制为 {fileSize} MB，档案时长限制为 {fileDuration} 分钟。目前支持所有常见的音频档案格式。",
	Pro_MS_Upload_Msg3: "您的档案过大，最大允许大小为 {fileSize} MB。",
	Pro_MS_Upload_Msg4: "档案时长超过了 {fileDuration} 分钟的限制。",
	Pro_MS_Upload_Msg5: "请求超时，请重新提交。",
	Pro_MS_Upload_Msg6: "档案上传中…",
	Pro_MS_Upload_Msg7: "翻译进行中，请稍候…",
	Pro_MS_Upload_Btn1: "上传",
	Pro_Setting_Menu11: "语音语言",
	Pro_Forgot_Pwd_Msg1: "此帐号不存在！",
	Pro_Forgot_Pwd_Msg2: "验证码正确！",
	Pro_Forgot_Pwd_Msg3: "您所输入的验证码不正确，请再试一次！"
}

/**i18多語字串：Spanish */
export const i18nString_es: I18nString = {
	"Welcome to Dr.AI": "Bienvenido a Dr.AI",
	"Pro_Home_Title2": "Profesionales de la Salud Empoderados Sin Fronteras",
	"Get Started": "Comenzar",
	"Dr.AI": "Dr.AI",
	"Today": "Hoy",
	"+ New Case": "+ Nuevo Caso",
	"-- Case is empty --": "-- El caso está vacío --",
	"credits": "créditos",
	"Valid until": "Válido hasta",
	"Logout": "Cerrar sesión",
	"Admission Note": "Nota de Admisión",
	"Progress Note": "Nota de Progreso",
	"Pharmacotherapy": "Farmacoterapia",
	"Cancer Strategy": "Estrategia contra el Cáncer",
	"Any Questions": "¿Alguna pregunta?",
	"Excellent": "Excelente",
	"Good": "Bueno",
	"Fair": "Regular",
	"Poor": "Deficiente",
	"Generating. Results will be ready in 1-3 minutes.": "Generando. Los resultados estarán listos en 1-3 minutos.",
	"Regenerate": "Regenerar",
	"Refine Admission Note & Generate Admission Orders": "Refinar Nota de Admisión y Generar Órdenes de Admisión",
	"Edit": "Editar",
	"Save": "Guardar",
	"Please enter a Note Title using a keyword or the patient's chart number for easy search.": "Por favor, introduce un Título para la Nota usando una palabra clave o el número de expediente del paciente para una búsqueda fácil.",
	"Admission Date": "Fecha de Admisión",
	"Note Title": "Título de la Nota",
	"Your case note": "Tu nota de caso",
	"Title": "Título",
	"Copy": "Copiar",
	"Download": "Descargar",
	"Save As": "Guardar Como",
	"Rename Title": "Renombrar Título",
	"Edit Message": "Editar Mensaje",
	"Your account balance is now depleted.": "El saldo de tu cuenta se ha agotado.",
	"My Package": "Mi Paquete",
	"Cancel": "Cancelar",
	"Please click on [Our plan] to access recharge functions.": "Por favor, haz clic en [Nuestro plan] para acceder a las funciones de recarga.",
	"Are you sure you want to delete this information": "¿Estás seguro de que quieres eliminar esta información?",
	"Yes": "Sí",
	"Disclaimer for Dr.AI": "Descargo de responsabilidad de Dr.AI",
	"I agree": "Estoy de acuerdo",
	"Next": "Siguiente",
	"Introducing Dr.AI": "Presentando Dr.AI",
	"Embracing the Future of Healthcare, Combing the Best of AI Technologies": "Abrazando el Futuro de la Atención Médica, Combinando lo Mejor de las Tecnologías de IA",
	"Empowering Healthcare Professionals:": "Empoderando a los Profesionales de la Salud:",
	"Unparalleled Data Analysis": "Análisis de Datos Sin Paralelo",
	"Assured Quality": "Calidad Asegurada",
	"Proactive Health Predictions": "Predicciones de Salud Proactivas",
	"Personalized Treatments": "Tratamientos Personalizados",
	"Collaborative Healthcare": "Atención Médica Colaborativa",
	"Evidence-Informed Recommendations": "Recomendaciones Informadas por la Evidencia",
	"Go Back": "Regresar",
	"Please enter your email and follow the provided instructions to complete your password change.": "Por favor, introduce tu correo electrónico y sigue las instrucciones proporcionadas para completar el cambio de contraseña.",
	"Forgot password": "Olvidé mi contraseña",
	"We have already sent a verification email to": "Ya hemos enviado un correo electrónico de verificación a",
	"Email": "Correo electrónico",
	"Submit": "Enviar",
	"Auth_Error_Msg1": "El código de verificación introducido es incorrecto.",
	"Enter verification code": "Introduce el código de verificación",
	"Resending…": "Reenviando…",
	"Resend Code": "Reenviar Código",
	"Log In": "Iniciar Sesión",
	"Email Address": "Dirección de Correo Electrónico",
	"Sign up": "Registrarse",
	"Hello": "Hola",
	"Password": "Contraseña",
	"Forgot password?": "¿Olvidaste la contraseña?",
	"Choose your plan": "Elige tu plan",
	"Select the best plan for your needs": "Selecciona el mejor plan para tus necesidades",
	"FREE Trial": "Prueba GRATIS",
	"USD for 3 months": "USD por 3 meses",
	"Sign up and instantly receive 300 credits!": "¡Regístrate y recibe instantáneamente 300 créditos!",
	"300 credits for use": "300 créditos para usar",
	"3-month trial period": "Período de prueba de 3 meses",
	"6 months of data preservation": "6 meses de preservación de datos",
	"Apply for Free Trial": "Solicitar Prueba Gratuita",
	"Basic": "Básico",
	"USD for 1 year": "USD por 1 año",
	"Begin experimenting with 300 credits over the course of a year.": "Comienza a experimentar con 300 créditos durante el transcurso de un año.",
	"1-year validity": "Validez de 1 año",
	"18 months of data preservation": "18 meses de preservación de datos",
	"Includes data recovery (available upon credit refill)": "Incluye recuperación de datos (disponible al recargar créditos)",
	"Coming Soon": "Próximamente",
	"Plus": "Plus",
	"For more flexibility, start experimenting with 600 credits over a one-year span.": "Para más flexibilidad, comienza a experimentar con 600 créditos durante un período de un año.",
	"600 credits for use": "600 créditos para usar",
	"Premium": "Premium",
	"USD per month": "USD por mes",
	"Unlimited access tailored for healthcare professionals.": "Acceso ilimitado adaptado para profesionales de la salud.",
	"Unlimited credits": "Créditos ilimitados",
	"Pro_Pack_Item6": "Limitación: 40 consultas en serie pausarán el análisis durante 3 horas",
	"Biomedical Technology, Inc.": "Tecnología Biomédica, Inc.",
	"not found": "no encontrado",
	"Create a New Account": "Crear una Nueva Cuenta",
	"Male": "Masculino",
	"Female": "Femenino",
	"Set up a password": "Establece una contraseña",
	"Confirm Password": "Confirmar Contraseña",
	"Your Detail": "Tus Detalles",
	"Pro_Home_Content1": "Dr.AI integra sin problemas la atención hospitalaria y ambulatoria, abordando los desafíos de los profesionales de la salud. En entornos hospitalarios, incorpora notas de admisión, órdenes y notas de progreso, junto con estrategias de farmacoterapia y cáncer.\n\nPara la atención ambulatoria, presenta notas SOAP, notas de enfermería y resúmenes de reuniones, simplificando las interacciones con los pacientes y automatizando los planes de tratamiento con soporte de diagnóstico para mejorar el camino clínico.", // 中間有換行, 記得加 '\n\n'
	"To thoroughly evaluate the patient's clinical conditions, Dr. AI needs specific information. This includes the patient's chief complaint, details of the current illness, a review of systems, the patient's past medical history, findings from the physical examination, and laboratory results. It is important to exclude any personally identifiable information, such as the patient's name or ID, from this data.": "Para evaluar a fondo las condiciones clínicas del paciente, Dr. AI necesita información específica. Esto incluye la principal queja del paciente, detalles de la enfermedad actual, una revisión de los sistemas, el historial médico pasado del paciente, los hallazgos del examen físico y los resultados de laboratorio. Es importante excluir cualquier información personalmente identificable, como el nombre o la identificación del paciente, de estos datos.",
	"Select the appropriate Admission Note for evaluation from the left column, and provide details regarding the Subjective (S) and Objective (O) aspects of the patient's condition. This should include the patient's symptoms, observable signs, and laboratory results. Please avoid including any personally identifiable information, such as the patient's name or ID. If the sidebar does not offer any selection options, it indicates that you need to first create the patient's Admission Note before moving on to the Progress Note analysis with Dr. AI.": "Selecciona la Nota de Admisión apropiada para la evaluación de la columna izquierda y proporciona detalles sobre los aspectos Subjetivos (S) y Objetivos (O) de la condición del paciente. Esto debe incluir los síntomas del paciente, signos observables y resultados de laboratorio. Por favor, evita incluir cualquier información personalmente identificable, como el nombre o la identificación del paciente. Si la barra lateral no ofrece opciones de selección, indica que necesitas primero crear la Nota de Admisión del paciente antes de pasar al análisis de la Nota de Progreso con Dr. AI.",
	"On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.": "On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.",
	"Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.": "Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.",
	"Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.": "Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.",
	"Don't have an account?": "¿No tienes una cuenta?",
	"Search...": "Search...",
	"Please check your email for the 6-digit verification code and enter it in the fields below": "Por favor revise su correo electrónico para ver el código de verificación de 6 dígitos e ingréselo en los campos a continuación",
	"Chief complaint, Present illness, Review of systems, Past medical history, Physical exam, and Laboratory results": "Motivo principal, Enfermedad actual, Revisión de sistemas, Historia clínica pasada, Examen físico y Resultados de laboratorio.",
	"The Subjective and Objective elements include the patient's symptoms, clinical signs, and Laboratory data": "Los elementos subjetivos y objetivos incluyen los síntomas del paciente, signos clínicos y datos de laboratorio.",
	"Questions regarding the patient's current medication and pertinent pharmacotherapy regimen.": "Preguntas sobre la medicación actual del paciente y el régimen farmacoterapéutico pertinente.",
	"Details on cancer type and stage, medical history, pathology reports, genetic testing results, performance status, radiological findings, treatment history, and patient preferences": "Detalles sobre el tipo y estadio del cáncer, historial médico, informes de patología, resultados de pruebas genéticas, estado funcional, hallazgos radiológicos, historial de tratamiento y preferencias del paciente.",
	"Raise any questions": "Plantea cualquier pregunta",
	"Your Email": "Tu correo electrónico",
	"fill in Captcha": "rellenar captcha",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, and numbers.": "La contraseña debe tener al menos 8 caracteres e incluir una combinación de letras mayúsculas, minúsculas y números.",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.": "La contraseña debe tener al menos 8 caracteres e incluir una combinación de letras mayúsculas y minúsculas, números y caracteres especiales.",
	"Enter password": "Introducir la contraseña",
	"Inquiries concerning the patient's ongoing medication or relevant pharmacotherapy regimen.": "Consultas sobre la medicación en curso del paciente o el régimen farmacoterapéutico relevante.",
	"Please enter your email and follow the instructions to finish changing your password.": "Por favor ingresa tu correo electrónico y sigue las instrucciones para terminar de cambiar tu contraseña.",
	"Pro_Home_Link1": "Para saber más",
	"Pro_Jump_Title1": "Para Registrarse en Dr.AI en su Dispositivo Móvil",
	"Pro_Jump_Title2": "La versión móvil de Dr.AI está actualmente en construcción.",
	"Pro_Jump_Content1": "Por favor, gire su teléfono al modo paisaje para registrarse.\nDespués de registrarse en su teléfono, por favor vuelva a su computadora de escritorio para disfrutar de la experiencia completa de Dr.AI. ¡Nos disculpamos por cualquier inconveniente que esto pueda causar!",
	"Pro_Jump_Content2": "P.D. Siéntase libre de previsualizar la interfaz de Dr.AI en acción con nuestra guía de vídeo.",
	Pro_Main_Menu1: "Paciente Hospitalizado",
	Pro_Main_Menu2: "Paciente Ambulatorio",
	Pro_Main_Menu3: "Asistente Médico de Documentación",
	Pro_SOAP_TTL: "Nota SOAP",
	Pro_SOAP_DSP: "La nota Subjective, Objective, Assessment, and Plan (SOAP) es un acrónimo que representa un método de documentación ampliamente utilizado por los proveedores de atención médica. En el entorno ambulatorio, para evaluar completamente la condición clínica de un paciente, el Dr. AI requiere información específica. Esto incluye los síntomas del paciente, la enfermedad actual, antecedentes médicos, hallazgos del examen físico y resultados de laboratorio. También puede seleccionar una transcripción de diálogo de la lista de grabaciones de asistentes médicos para generar la nota SOAP. Alternativamente, puede continuar la conversación para hacer un seguimiento de una nota SOAP ya creada. Es crucial excluir cualquier información de identificación personal, como el nombre o la identificación del paciente, de estos datos.",
	Pro_SOAP_TIP: "Ingrese los síntomas, signos clínicos, datos de laboratorio y una transcripción directa del diálogo médico, o continúe la conversación siguiendo la nota.",
	Pro_MS_List_TTL: "Lista de Asistentes Médicos para Grabaciones de Voz:",
	Pro_MS_List_DSP: "Seleccione un elemento de la lista de grabaciones de voz para un análisis más detallado.",
	Pro_MS_List_Msg1: "Sin datos",
	Pro_MS_Recording_TTL: "Nota del Asistente Médico",
	Pro_MS_Recording_Msg1: "¿Guardar antes de salir?",
	Pro_Setting_Btn1: "Confirmar",
	Pro_Setting_Menu1: "Plan actual",
	Pro_Setting_Menu2: "Lenguaje del sistema",
	Pro_Setting_Menu3: "Nombre",
	Pro_Setting_Menu4: "Idioma de respuesta",
	Pro_Setting_Version: "Versión",
	Pro_Tab_Topic_Title: "Elija Servicios",
	Pro_Tab_Menu1: "Lista de escribanos médicos",
	Pro_Tab_Menu2: "Historia del caso",
	Pro_Tab_Menu3: "Ajustes",
	Settings: "Ajustes",
	Pro_NursingN_DSP: "La función de Nota de Enfermería documenta las acciones de cuidado, observaciones de enfermería e intervenciones médicas realizadas por el personal de enfermería. Apoya los formatos de registros médicos estandarizados globalmente y puede generar automáticamente formatos como SOAPIE, ISBAR y DAR a petición. La Nota de Enfermería integral ayuda en la coordinación de traspasos entre el personal de enfermería dentro del hospital y mejora la atención y comunicación con el paciente. Es esencial excluir cualquier información personal identificable.",
	Pro_NursingN_TIP: "Introduce los datos subjetivos y objetivos, incluyendo observaciones de enfermería, acciones de cuidado e intervenciones médicas.",
	Pro_ConsultD_DSP: "Consultar al Dr. AI proporciona acceso a orientación profesional y consejos para apoyar la toma de decisiones clínicas, mejorando así la calidad y efectividad de la atención al paciente. También puedes participar en discusiones continuas sobre la situación clínica del paciente para explorar más posibilidades clínicas. Por favor, asegúrate de excluir cualquier información personal identificable de los datos.",
	Pro_ConsultD_TIP: "Introduce la serie de preguntas para el análisis exhaustivo.",
	Pro_MeetingR_DSP: "La función de Resumen de Reunión genera automáticamente resúmenes de discusiones, decisiones y acciones a tomar de las reuniones del equipo de salud, adhiriéndose a los formatos médicos estándar. Esto asegura una comunicación clara y alineación en los planes de atención y objetivos del paciente. Para el análisis de voz de las reuniones de discusión de casos, se recomienda que cada caso se grabe en un archivo de audio separado.",
	Pro_MeetingR_TIP: "Por favor, selecciona el archivo de grabación de voz de la reunión para el análisis.",
	Pro_MS_Upload_Msg1: "Seleccione el formato de archivo admitido.",
	Pro_MS_Upload_Msg2: "El límite de tamaño de archivo es de {uploadFileMaxSize} MB. Los formatos de archivo admitidos actualmente son: {uploadFileFormats}",
	Pro_MS_Upload_Msg2_Size: "El límite de tamaño de archivo es de {uploadFileMaxSize} MB.",
	Pro_System_Msg1: "Cargando…",
	Pro_System_Msg2: "Procesando…",
	Pro_System_Msg3: "El sistema está ocupado, inténtelo de nuevo más tarde.",
	Pro_NursingN_TTL: "Nota de enfermería",
	Pro_ConsultD_TTL: "Consulte al Dr. AI",
	Pro_MeetingR_TTL: "Resumen de la reunión",
	Pro_Setting_Btn2: "Editar perfil personal",
	Pro_Setting_Btn3: "Más planes",
	Pro_Setting_Btn4: "Historial de cuenta",
	Pro_Setting_Btn5: "Editar",
	Pro_Setting_Menu5: "Apodo",
	Pro_Setting_Menu6: "Plan",
	Pro_Setting_Menu7: "Saldo de créditos de la cuenta",
	Pro_Setting_Menu8: "Fecha de caducidad",
	Pro_Setting_Menu9: "Dirección de Correo Electrónico",
	Pro_System_Msg4: "¡Copiado!",
	Pro_Signup_Msg1: "¡Gracias por registrarte! Por favor, revisa tu bandeja de entrada para verificar tu cuenta. Además, recuerda revisar tu carpeta de spam para asegurarte de recibir nuestro correo electrónico.",
	Pro_Setting_Menu10: "Idioma",
	Pro_Setting_Msg1: "Seleccione un archivo de imagen válido (jpg o png).",
	Pro_LogOut_Msg1: "¿Está seguro de que desea cerrar sesión?",
	Pro_SignIn_Content1: "O inicia sesión con",
	Pro_SignIn_Msg1: `¡Recordatorio! Si es la primera vez que inicia sesión en este sitio web utilizando la función "Iniciar sesión con Apple", asegúrese de configurar la opción de correo electrónico en "Compartir mi correo electrónico".`,
	Pro_SignIn_Msg2: `El sistema ha detectado que seleccionaste la opción "Ocultar mi correo electrónico". Restablezca la configuración e inténtelo de nuevo.`,
	Pro_SignIn_Msg3: "El sistema ha detectado que su navegador está bloqueando las ventanas emergentes. Por favor revise la configuración de su navegador e intente de nuevo.",
	Pro_SignIn_Msg4: "enviado",
	Pro_SignIn_Msg5: "Su correo electrónico o contraseña es incorrecto. Inténtalo de nuevo.",
	Pro_SignIn_Msg6: "Por favor, introduce tu dirección de correo electrónico.",
	Pro_SignIn_Msg7: "¡El correo electrónico fue registrado!",
	Pro_Register_Msg1: "¡La contraseña es incorrecta!",
	Pro_Register_Msg2: "El registro falló, ¡póngase en contacto con el servicio de atención al cliente!",
	Pro_Alert_Msg1: "La función Nota de Progreso está bloqueada.",
	Pro_Alert_Msg2: "Para guardar la Nota de Progreso es necesario guardar primero una Nota de Admisión relacionada.",
	Pro_Alert_Msg3: "Por favor complete el título.",
	Pro_Alert_Msg4: "Sólo disponible en la Nota de Admisión.",
	Pro_Alert_Msg5: "Sólo disponible después de guardar.",
	Pro_Alert_Msg6: "Refinar la Nota de Admisión sólo es posible después de enviar el mensaje.",
	Pro_Alert_Msg7: "Seleccione un tipo de servicio disponible.",
	Pro_Alert_Msg8: "Sólo es posible guardar después de recibir una respuesta al mensaje.",
	Pro_Alert_Msg9: "La regeneración sólo es posible después de enviar el mensaje.",
	Pro_Alert_Msg10: "Este campo no puede estar vacío.",
	Pro_SearchCase_Title: "Caso de búsqueda",
	Pro_SearchCase_Content1: "Tipo de servicios",
	Pro_Date_String: "Fecha",
	Pro_Search_Btn: "Buscar",
	Pro_Clear_Btn: "Limpiar",
	Pro_Signup_Btn1: "Reenviar E-Mail de activación",
	Pro_Setting_Pwd_Menu1: "Contraseña actual",
	Pro_Setting_Pwd_Menu2: "Nueva contraseña",
	Pro_Setting_Pwd_Menu3: "Confirmar nueva contraseña",
	Pro_Setting_Pwd_Msg1: "La contraseña original que ingresó es incorrecta. ¡Verifique e inténtelo nuevamente!",
	Pro_Setting_Pwd_Msg2: "¡Contraseña cambiada con éxito!",
	Pro_SignIn_Msg8: "Ya no se puede acceder a su cuenta. ¡Póngase en contacto con el servicio de atención al cliente!",
	Pro_Alert_Msg11: "¿Estás segura de que quieres salir de esta página?",
	Pro_Alert_Msg12: "Tu cuenta ha estado inactiva durante demasiado tiempo. ¡Por favor inicia sesión nuevamente!",
	Pro_MS_Type_Menu1: "Toda",
	Pro_MS_Type_Menu2: "Grabación en vivo",
	Pro_MS_Type_Menu3: "Subir archivo",
	Pro_Type_String: "Tipo",
	Pro_SignIn_Btn1: "Iniciar sesión con Apple",
	Pro_SignIn_Btn2: "Iniciar sesión con Google",
	Pro_SignIn_Msg9: "Tu cuenta no ha sido activada. Revisa tu correo electrónico y completa la verificación o vuelve a enviar el correo electrónico de activación e inténtalo nuevamente.",
	Pro_MS_Upload_TTL: "Subir archivo de audio",
	Pro_MS_Upload_DSP: "El límite de tamaño de archivo es de {fileSize} MB y la duración máxima de archivo es de {fileDuration} minutos. Actualmente, se admiten todos los formatos de archivos de audio habituales.",
	Pro_MS_Upload_Msg3: "Su archivo es demasiado grande; el tamaño máximo permitido es {fileSize} MB.",
	Pro_MS_Upload_Msg4: "El archivo supera la duración máxima de {fileDuration} minutos.",
	Pro_MS_Upload_Msg5: "Se agotó el tiempo de espera de la solicitud. Intente enviarla nuevamente.",
	Pro_MS_Upload_Msg6: "Subiendo archivo…",
	Pro_MS_Upload_Msg7: "Traducción en proceso. Por favor espere…",
	Pro_MS_Upload_Btn1: "Subir archivo",
	Pro_Setting_Menu11: "Lenguaje del habla",
	Pro_Forgot_Pwd_Msg1: "¡Esta cuenta no existe!",
	Pro_Forgot_Pwd_Msg2: "¡El código de verificación es correcto!",
	Pro_Forgot_Pwd_Msg3: "El código de verificación ingresado es incorrecto. ¡Inténtelo de nuevo!"
}

/**i18多語字串：한국어 */
export const i18nString_kr: I18nString = {
	Settings: "설정",
	"Welcome to Dr.AI": "Dr.AI 에 오신것을 환영합니다",
	Pro_Home_Title2: "국경 없는 의료전문가 역량 강화",
	"Get Started": "시작하다",
	"Dr.AI": "Dr.AI",
	"Today": "오늘",
	"+ New Case": "+ 새로운 케이스",
	"-- Case is empty --": "-- 현재 케이스가 없습니다 --",
	"credits": "포인트",
	"Valid until": "유효 기한",
	"Logout": "로그아웃",
	"Admission Note": "입원 기록",
	"Progress Note": "경과 기록",
	"Pharmacotherapy": "약물 치료",
	"Cancer Strategy": "암 치료 전략",
	"Any Questions": "질문이 있으십니까",
	"Excellent": "우수",
	"Good": "좋음",
	"Fair": "보통",
	"Poor": "저조",
	"Generating. Results will be ready in 1-3 minutes.": "분석 중입니다...1~3분 정도 기다려 주세요.",
	"Regenerate": "재생하다",
	"Refine Admission Note & Generate Admission Orders": "입원기록 최적화 및 입원의 당부 생성",
	"Edit": "편집",
	"Save": "저장",
	"Please enter a Note Title using a keyword or the patient's chart number for easy search.": "간단한 검색을 위해 키워드 또는 환자의 병례 번호를 사용하여 제목을 입력하세요",
	"Admission Date": "입원일",
	"Note Title": "제목",
	"Your case note": "당신의 병력 기록",
	"Title": "제목",
	"Copy": "복사",
	"Download": "다운로드",
	"Save As": "다른 이름으로 저장",
	"Rename Title": "제목 이름 바꾸기",
	"Edit Message": "메시지 편집하기",
	"Your account balance is now depleted.": "계정 잔액이 소진되었습니다.",
	"My Package": "나의 플랜",
	"Cancel": "취소",
	"Please click on [Our plan] to access recharge functions.": "충전 기능에 액세스하려면 [우리의 플랜]을 클릭하세요",
	"Are you sure you want to delete this information": "이 정보를 삭제하시겠습니까?",
	"Yes": "예",
	"Disclaimer for Dr.AI": "Dr.AI의 면책 조항",
	"I agree": "동의하다",
	"Next": "다음",
	"Introducing Dr.AI": "Dr.AI소개",
	"Embracing the Future of Healthcare, Combing the Best of AI Technologies": "최첨단 AI기술을 결합하여 의료의 미래를 수용하다",
	"Empowering Healthcare Professionals:": "의료전문가의 역량 강화:",
	"Unparalleled Data Analysis": "비교할 수 없는 데이터 분석 기능",
	"Assured Quality": "품질 보증",
	"Proactive Health Predictions": "능동적 건강 예측",
	"Personalized Treatments": "맞춤형 치료",
	"Collaborative Healthcare": "협력적인 의료",
	"Evidence-Informed Recommendations": "실증에 근거한 권장 사항",
	"Go Back": "돌아가기",
	"Please enter your email and follow the provided instructions to complete your password change.": "이메일을 입력하고 지시에 따라 비밀번호 변경을 완료하세요",
	"Forgot password": "비밀번호를 잊어버리셨나요",
	"We have already sent a verification email to": "확인 메일을 보냈습니다",
	"Email": "이메일",
	"Submit": "인증하다",
	Auth_Error_Msg1: "입력한 인증코드가 올바르지 않습니다.",
	"Enter verification code": "인증코드를입력하세요",
	"Resending…": "재전송 중",
	"Resend Code": "인증 코드를 재전송하다",
	"Log In": "로그인",
	"Email Address": "이메일 주소",
	"Sign up": "등록하다",
	"Hello": "안녕하세요",
	"Password": "비밀번호",
	"Forgot password?": "비밀번호를 잊어버리셨나요",
	"Choose your plan": "플랜을 선택하다",
	"Select the best plan for your needs": "요구에 가장 적합한 플랜을 선택하세요",
	"FREE Trial": "무료 시용",
	"USD for 3 months": "3개월간의 USD",
	"Sign up and instantly receive 300 credits!": "지금 바로 등록해서 300포인트 획득!",
	"300 credits for use": "300포인트 이용가능",
	"3-month trial period": "3개월 시용 기간",
	"6 months of data preservation": "6개월 데이터 저장",
	"Apply for Free Trial": "무료 시용을 신청하다",
	"Basic": "기본 버전",
	"USD for 1 year": "1년간의 USD",
	"Begin experimenting with 300 credits over the course of a year.": "1년간 300포인트를 이용해 체험하다",
	"1-year validity": "1년간의 유효 기간",
	"18 months of data preservation": "18개월 데이터 저장",
	"Includes data recovery (available upon credit refill)": "데이터 복구 포함(포인트을 충전하는 동시에 복원)",
	"Coming Soon": "근일공개",
	"Plus": "플러스 버전",
	"For more flexibility, start experimenting with 600 credits over a one-year span.": "유연성 더 커져 1년에 600포인트로 체험하다",
	"600 credits for use": "600포인트 이용가능",
	"Premium": "프리미엄 버전",
	"USD per month": "월정액의 USD",
	"Unlimited access tailored for healthcare professionals.": "의료 전문가를 위한 맞춤형 무제한 플랜",
	"Unlimited credits": "무제한 포인트",
	Pro_Pack_Item6: "제한사항:40회 연속 검색하면 분석이 3시간 중단",
	"Biomedical Technology, Inc.": "Biomedical Technology, Inc.",
	"not found": "찾을 수 없습나다",
	"Create a New Account": "새 계정 만들기",
	"Set up a password": "비밀번호 설정",
	"Confirm Password": "비밀번호 확인",
	Pro_Home_Content1: "Dr.AI는 입원 환자와 외래 환자 관리를 원활하게 통합하여 의료전문가의 문제를 해결합니다. 입원 환자 케어에 대해서, 약물 요법이나 암 전략에 더해, 입원 기록, 의사의 지시, 경과 기록도 포함됩니다. 외래진료의 경우 SOAP기록, 간호기록, 회의개요를 갖춰 의사와 환자의 소통을 간소화하고 치료계획을 자동으로 생성합니다. 또한 보조적인 진단 지원을 제공함으로써 클리니컬 패스가 강화됩니다.",
	"To thoroughly evaluate the patient's clinical conditions, Dr. AI needs specific information. This includes the patient's chief complaint, details of the current illness, a review of systems, the patient's past medical history, findings from the physical examination, and laboratory results. It is important to exclude any personally identifiable information, such as the patient's name or ID, from this data.": "환자의 임상 상태를 철저히 평가하려면 특정 정보가 필요합니다. 환자의 주요소견, 현재 질병의 세부 사항, 시스템 검토, 환자의 과거 병력, 신체 검사 소견 및 검사 결과가 포함됩니다. 환자의 이름이나 ID 등 개인을 식별할 수 있는 정보의 입력을 피하는 것이 중요합니다.",
	"Select the appropriate Admission Note for evaluation from the left column, and provide details regarding the Subjective (S) and Objective (O) aspects of the patient's condition. This should include the patient's symptoms, observable signs, and laboratory results. Please avoid including any personally identifiable information, such as the patient's name or ID. If the sidebar does not offer any selection options, it indicates that you need to first create the patient's Admission Note before moving on to the Progress Note analysis with Dr. AI.": "왼쪽 열에서 적절한 입원 기록을 선택하고 환자의 주관적(S) 및 객관적(O) 상태에 대한 자세한 정보를 제공하세요. 환자의 증상, 관찰 가능한 징후 및 검사 결과를 포함해야합니다. 환자의 이름이나 ID등 개인을 특정할 수 있는 정보를 입력하지 마세요. 사이드바에 선택 옵션이 없으면 Dr. AI에서 경과기록 분석으로 이동하기 전에 먼저 환자의 입원기록을 작성할 필요가 있습니다.",
	"On the Dr. AI platform, healthcare professionals have access to an advanced and comprehensive drug database. This leading-edge system quickly and accurately retrieves a wide range of information, including side effects, monitoring parameters, adverse events, and precautions for a comprehensive variety of pharmaceuticals. Additionally, you can choose the relevant Admission Note for evaluation from the sidebar on the left and enter questions related to the patient's pharmacotherapy. Please be mindful to exclude any personally identifiable information, such as the patient's name or ID. If there are no options available for selection in the sidebar, this indicates that you need to first create the patient's Admission Note before proceeding with Dr. AI's pharmacotherapy analysis.": "Dr. AI 플랫폼을 통해 의료 전문가는 선진적이고 포괄적인 약제 데이터베이스에 액세스할 수 있습니다. 부작용, 모니터링 파라미터, 유해현상, 약제의 주의사항 등의 다양한 정보를 신속하고 정확하게 취득할 수 있습니다. 또한 왼쪽 사이드바에서 관련 입원기록을 선택하여 평가하고 환자의 약물 치료와 관련된 질문을 입력할 수 있습니다. 환자의 이름이나 ID등 개인을 특정할 수 있는 정보를 입력하지 마세요. 사이드바에서 선택 옵션이 없는 경우 Dr. AI에서 약물 치료 분석으로 이동하기 전에 먼저 환자의 입원기록을 작성할 필요가 있습니다.",
	"Creating a cancer treatment plan requires comprehensive patient information. Essential data such as an in-depth medical history, the type and stage of cancer, pathology reports, results from genetic testing, performance status, radiology reports, previous treatment history, and the patient's preferences should be provided. Additionally, you can select the appropriate Admission Note for review from the sidebar. It is crucial to ensure that no personally identifiable information, such as the patient's name or ID, is included. If the sidebar does not display any selectable options, this indicates that your initial step should be to create the patient's Admission Note before moving on to developing cancer treatment strategies with Dr. AI.": "암 치료 계획의 작성에는 환자의 상세한 정보가 필요합니다. 상세한 의료 병력, 암의 종류와 단계, 병리 보고, 유전자 검사 결과,  상태, 방사선 보고, 이전 치료 이력, 환자 선호 등의 데이터를 제공해야 합니다. 또한 사이드바에서 적절한 입원 기록을 선택하여 검토할 수 있습니다. 환자의 이름이나 ID 등의 개인을 특정하는 정보를 포함하지 마세요. 사이드바에 선택 옵션이 없는 경우 암 치료 전략을 세우기 전에 먼저 환자 입원 기록을 작성할 필요가 있습니다.",
	"Dr. AI is engineered to monitor a wide range of patient health factors at different phases of their hospital stay, including at admission, throughout their stay, upon discharge, and after discharge. Please feel free to ask any questions. Additionally, you can select the appropriate Admission Note for analysis from the left sidebar. Remember to exclude any personal identifiers, like the patient's name or ID. If you don't see any options in the sidebar, it means you should create the patient's Admission Note first, before moving on to a Q&A session with Dr. AI.": "Dr. AI는 입원 시, 입원 중, 퇴원 시, 퇴원 후의 다른 단계에서 다양한 환자의 건강 요인을 모니터링할 수 있도록 설계되었습니다. 문의사항 있으시면 편하게 질문해 주세요. 또한 왼쪽 사이드바에서 적절한 입원기록을 선택하여 분석을 진행할 수 있습니다. 환자의 이름이나 ID 등의 개인을 특정하는 정보를 포함하지 마세요. 사이드바에 선택 옵션이 없으면 Q&A 세션으로 이동하기 전에 먼저 환자 입원기록을 작성할 필요가 있습니다.",
	"Don't have an account?": "계정을 가지고 있지 않습니까?",
	"Search...": "검색…",
	"Please check your email for the 6-digit verification code and enter it in the fields below": "이메일로 6자리 인증코드를 확인하신 후 아래 란에 입력해주세요.",
	"Chief complaint, Present illness, Review of systems, Past medical history, Physical exam, and Laboratory results": "주요소견, 현질환, 시스템검토, 과거병력, 신체검사, 검사실 결과",
	"The Subjective and Objective elements include the patient's symptoms, clinical signs, and Laboratory data": "주관적 및 객관적 요소에는 환자의 증상, 임상 징후 및 검사 데이터가 포함됩니다.",
	"Questions regarding the patient's current medication and pertinent pharmacotherapy regimen.": "현재 약물 치료 및 관련 약물 치료 계획에 대한 질문",
	"Details on cancer type and stage, medical history, pathology reports, genetic testing results, performance status, radiological findings, treatment history, and patient preferences": "암의 종류와 단계, 병력, 병리 보고서, 유전자 검사 결과, 수행도 상태, 방사선 소견, 치료 이력, 환자 선호도에 관한 세부 사항",
	"Raise any questions": "질문하다",
	"Your Email": "이메일",
	"fill in Captcha": "인증 코드를 입력하세요",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, and numbers.": "비밀번호는 최소 8자로 대문자와 소문자, 숫자를 포함해야 합니다.",
	"The password should be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.": "비밀번호는 최소 8자로 대소문자, 숫자, 특수문자를 포함해야 합니다.",
	"Enter password": "비밀번호를 입력하세요",
	"Inquiries concerning the patient's ongoing medication or relevant pharmacotherapy regimen.": "진행중인 약물 치료 또는 관련 약물 치료 계획에 관한 질문",
	"Please enter your email and follow the instructions to finish changing your password.": "이메일을 입력하고 지시에 따라 비밀번호 변경을 완료하세요",
	Pro_Home_Link1: "더 알아보기",
	Pro_Jump_Title1: "휴대전화로 Dr.AI에 등록하기",
	Pro_Jump_Title2: "현재 Dr.AI 모바일 버전은 개발 중입니다.",
	Pro_Jump_Content1: "등록을 진행하기 위해 휴대폰을 가로 돌려주세요.\n휴대폰으로 등록이 완료된 후 데스크톱으로 돌아가 완전한 Dr.AI를 체험해 보세요. 불편을 드려 죄송합니다!",
	Pro_Jump_Content2: "P.S. Dr.AI의 인터페이스 조작 동영상 가이드를 미리 볼 수 있습니다.",
	Pro_Main_Menu1: "입원 환자",
	Pro_Main_Menu2: "외래 환자",
	Pro_Main_Menu3: "의료서기",
	Pro_SOAP_TTL: "SOAP기록",
	Pro_SOAP_DSP: "주관, 객관, 평가, 계획(SOAP) 기록은 많은 의료전문가가 널리 사용하는 기재방법입니다. 외래 환자의 임상 상태를 철저히 평가하기 위해 Dr.AI는 특정 정보가 필요합니다. 환자의 증상, 현재 상태, 과거 병력, 신체검사 결과 및 실험실 결과가 포함됩니다. 또한 의료서기의 녹음 목록에서 대화 기록을 선택하여 SOAP를 생성할 수 있습니다. 이미 작성된 SOAP 기록을 바탕으로 대화를 이어갈 수도 있습니다. 데이터에서 환자의 이름이나 ID 등 개인을 식별할 수 있는 정보를 제외하세요",
	Pro_SOAP_TIP: "증상, 임상적 징후, 실험실 데이터, 직접적인 의료 대화 기록을 입력하거나 기록을 기반으로 대화를 이어가세요.",
	Pro_NursingN_TTL: "간호 기록",
	Pro_NursingN_DSP: "간호 기록은 간호 직원이 수행하는 간호 행동, 간호 관찰 및 의료 개입을 기록하는 기능입니다. 그리고 글로벌 적용 의료기록 포맷을 채택하여 SOAPIE, ISBAR, DAR 포맷을 지시에 따라 자동 생성할 수 있습니다. 포괄적인 간호 기록은 병원 내 간호 직원의 인수인계 조정을 돕고 환자의 케어와 의사 소통을 보조합니다. 개인을 식별할 수 있는 정보를 반드시 제외하세요.",
	Pro_NursingN_TIP: "주관적 및 객관적인 데이터를 입력하세요. 간호 관찰, 간호 행동 및 의료 개입이 포함됩니다.",
	Pro_ConsultD_TTL: "Dr.AI상담",
	Pro_ConsultD_DSP: "Dr.AI의 상담 기능을 사용하여 임상 판단을 지원하고 환자 케어의 품질과 성과를 향상시키기 위한 Dr.AI의 전문적인 지침과 조언을 구할 수 있습니다. 임상 가능성을 더 이해하기 위해 환자의 임상 상황에 따라 연속되는 문제를 제출하고 논의할 수도 있습니다. 개인을 식별할 수 있는 정보를 반드시 제외하세요.",
	Pro_ConsultD_TIP: "종합적인 분석을 위해 일련의 질문을 입력하세요.",
	Pro_MeetingR_TTL: "회의 요약",
	Pro_MeetingR_DSP: "회의 요약 기능은 의료팀 회의 논의, 결정 및 액션 아이템의 개요를 표준 의료 형식에 따라 자동 생성하여 의료팀의 환자 케어 계획과 목표가 일치하도록 확보합니다. 사례토의회 음성분석의 경우 각 사례별로 독립된 음성파일로 기록하는 것이 권장합니다.",
	Pro_MeetingR_TIP: "분석할 회의의 음성 녹음 파일을 선택하세요.",
	Pro_MS_List_TTL: "의료서기의 음성 목록:",
	Pro_MS_List_DSP: "추가 분석을 위해 음성 목록에서 항목을 선택하세요.",
	Pro_MS_List_Msg1: "데이터 없음",
	Pro_MS_Recording_TTL: "의료서기 기록",
	Pro_MS_Recording_Msg1: "저장 후 종료하시겠습니까?",
	Pro_MS_Upload_Msg1: "지원되는 파일 형식을 선택하세요",
	Pro_MS_Upload_Msg2: "파일 크기 제한은 {uploadFileMaxSize} MB입니다. 현재 지원되는 파일 형식은 다음과 같습니다: {uploadFileFormats}",
	Pro_MS_Upload_Msg2_Size: "파일 크기 제한은 {uploadFileMaxSize} MB입니다.",
	Pro_Setting_Btn1: "확인",
	Pro_Setting_Btn2: "개인정보 편집",
	Pro_Setting_Btn3: "더 많은 옵션",
	Pro_Setting_Btn4: "계정 세부정보",
	Pro_Setting_Btn5: "편집",
	Pro_Setting_Menu1: "현재 플랜",
	Pro_Setting_Menu2: "시스템 언어",
	Pro_Setting_Menu3: "이름",
	Pro_Setting_Menu4: "응답 언어",
	Pro_Setting_Menu5: "별명",
	Pro_Setting_Menu6: "플랜",
	Pro_Setting_Menu7: "포인트 잔액",
	Pro_Setting_Menu8: "유효 기간",
	Pro_Setting_Menu9: "이메일 주소",
	Pro_Setting_Version: "버전",
	Pro_Tab_Topic_Title: "서비스 선택",
	Pro_Tab_Menu1: "의료서기 목록",
	Pro_Tab_Menu2: "과거의 병력",
	Pro_Tab_Menu3: "설정",
	Pro_System_Msg1: "로드 중…",
	Pro_System_Msg2: "처리 중…",
	Pro_System_Msg3: "지금은 서버가 혼잡한 상태입니다. 나중에 다시 시도해 주세요.",
	Pro_System_Msg4: "복사되었습니다!",
	Pro_Signup_Msg1: "등록해주셔서 감사합니다! 계정을 인증하기 위해 이메일을 확인하세요. 또한 정크 메일 폴더도 확인하여 메일을 확실하게 수신할 수 있도록 하세요.",
	Pro_Setting_Menu10: "언어",
	Pro_Setting_Msg1: "유효한 이미지 파일(jpg 또는 png)을 선택해 주세요",
	Pro_LogOut_Msg1: "로그아웃하시겠습니까?",
	Pro_SignIn_Content1: "다른 계정으로 로그인",
	Pro_SignIn_Msg1: `"Apple 계정으로 로그인" 기능을 통해 이 사이트에 처음 로그인하는 경우 이메일 옵션을 “나의 이메일 공유하기” 로 설정하세요`,
	Pro_SignIn_Msg2: `이메일 비공개' 옵션이 선택된 것으로 검출되었습니다. 재설정하고 다시 시도하세요`,
	Pro_SignIn_Msg3: "브라우저가 팝업 표시를 차단하고 있는 것이 검출되었습니다.브라우저 설정을 확인하고 다시 시도하세요",
	Pro_SignIn_Msg4: "전송 된",
	Pro_SignIn_Msg5: "이메일이나 비밀번호가 올바르지 않습니다. 다시 시도해 주세요.",
	Pro_SignIn_Msg6: "이메일 주소를 입력하세요.",
	Pro_SignIn_Msg7: "이메일이 이미 등록되었습니다!",
	Pro_Register_Msg1: "비밀번호가 올바르지 않습니다",
	Pro_Register_Msg2: "등록에 실패했습니다. 고객센터에 문의하세요!",
	Pro_Alert_Msg1: "경과 기록 기능이 잠겨 있습니다.",
	Pro_Alert_Msg2: "경과기록을 저장하려면 관련된 입원기록이 이미 있는 것이 필요합니다",
	Pro_Alert_Msg3: "제목을 입력해주세요",
	Pro_Alert_Msg4: "입원기록만 사용가능",
	Pro_Alert_Msg5: "저장 후에만 사용 가능",
	Pro_Alert_Msg6: "입원기록은 메시지를 보낸 후에만 최적화될 수 있습니다.",
	Pro_Alert_Msg7: "이용 가능한 서비스 종류를 선택해주세요.",
	Pro_Alert_Msg8: "회신 받은 후에 메시지를 저장할 수 있습니다",
	Pro_Alert_Msg9: "메시지를 보낸 후에만 다시 생성할 수 있습니다.",
	Pro_Alert_Msg10: "이 필드는 비워둘 수 없습니다.",
	Pro_SearchCase_Title: "사례 검색",
	Pro_SearchCase_Content1: "서비스 종류",
	Pro_Date_String: "날짜",
	Pro_Search_Btn: "검색",
	Pro_Clear_Btn: "클리어",
	Pro_Signup_Btn1: "활성화 편지 다시 보내기",
	Pro_Setting_Pwd_Menu1: "현재 비밀번호",
	Pro_Setting_Pwd_Menu2: "새 비밀번호",
	Pro_Setting_Pwd_Menu3: "새 비밀번호를 다시 입력하세요.",
	Pro_Setting_Pwd_Msg1: "현재 비밀번호가 잘못 입력되었습니다. 확인 후 다시 시도해 주세요!",
	Pro_Setting_Pwd_Msg2: "비밀번호가 변경되었습니다!",
	Pro_SignIn_Msg8: "계정에 로그인할 수 없습니다. 고객 서비스에 문의하세요!",
	Pro_Alert_Msg11: "이 페이지를 떠나시겠습니까?",
	Pro_Alert_Msg12: "계정은 시간 초과로 인해 유휴 상태입니다. 다시 로그인하세요!",
	Pro_MS_Type_Menu1: "모두",
	Pro_MS_Type_Menu2: "녹음",
	Pro_MS_Type_Menu3: "업로드",
	Pro_Type_String: "유형",
	Pro_SignIn_Btn1: "Apple 로그인",
	Pro_SignIn_Btn2: "Google 로그인",
	Pro_SignIn_Msg9: "계정이 활성화되지 않았습니다. 이메일을 확인하고 인증을 완료하거나 활성화 편지를 다시 보내고 다시 시도하세요.",
	Pro_MS_Upload_TTL: "오디오 파일 업로드",
	Pro_MS_Upload_DSP: "파일 크기는 {fileSize} MB로 제한되고 시간은 {fileDuration} 분으로 제한됩니다. 현재 모든 일반적인 오디오 파일 형식을 지원합니다.",
	Pro_MS_Upload_Msg3: "파일이 너무 큽니다. 허용되는 최대 크기는 {fileSize} MB입니다.",
	Pro_MS_Upload_Msg4: "파일 시간이 {fileDuration} 분 제한을 초과했습니다.",
	Pro_MS_Upload_Msg5: "요청 시간이 초과되었습니다. 다시 제출해 주세요.",
	Pro_MS_Upload_Msg6: "파일 업로드 중…",
	Pro_MS_Upload_Msg7: "번역이 진행 중입니다. 잠시 기다려 주세요…",
	Pro_MS_Upload_Btn1: "업로드",
	Pro_Setting_Menu11: "음성 언어",
	Pro_Forgot_Pwd_Msg1: "이 계정은 존재하지 않습니다!",
	Pro_Forgot_Pwd_Msg2: "인증번호가 정확합니다!",
	Pro_Forgot_Pwd_Msg3: "입력하신 인증번호가 올바르지 않습니다. 다시 시도해 주세요!"
}